.homePage {
    min-height: 100vh;
    background: linear-gradient(to bottom right, #1a202c, #000000);
    color: white;
    font-family: interRegular, sans-serif;
    overflow: hidden;
    position: relative;
  }
  
  .backgroundLights {
    position: absolute;
    inset: 0;
    overflow: hidden;
    opacity: 0.3;
  }
  
  .light {
    position: absolute;
    border-radius: 50%;
    filter: blur(60px);
    mix-blend-mode: multiply;
  }
  
  .light1 {
    composes: light;
    width: 400px;
    height: 400px;
    background-color: #1e40af;
    top: 25%;
    left: 25%;
    animation: float 20s infinite alternate;
  }
  
  .light2 {
    composes: light;
    width: 300px;
    height: 300px;
    background-color: #9a3412;
    top: 33%;
    right: 25%;
    animation: float 15s infinite alternate-reverse;
  }
  
  .light3 {
    composes: light;
    width: 350px;
    height: 350px;
    background-color: #854d0e;
    bottom: 25%;
    left: 33%;
    animation: float 18s infinite alternate;
  }
  
  @keyframes float {
    0% { transform: translate(0, 0); }
    100% { transform: translate(50px, 50px); }
  }
  
  .container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 4rem 1rem;
    position: relative;
    z-index: 1;
  }
  
  .hero {
    text-align: center;
    margin-bottom: 4rem;
  }
  
  .mainTitle {
    font-size: 4rem;
    font-weight: bold;
    margin-bottom: 1rem;
    background: linear-gradient(to right, #2563eb, #22d3ee);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  
  .subtitle {
    font-size: 1.5rem;
    color: #d1d5db;
    margin-bottom: 0.5rem;
  }
  
  .secondaryTitle {
    font-size: 3rem;
    font-weight: bold;
    margin-bottom: 1rem;
    background: linear-gradient(to right, #eab308, #ea580c);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  
  .animatedText {
    font-size: 1.5rem;
    color: #d1d5db;
    margin-bottom: 2rem;
    min-height: 2em;
    transition: opacity 0.5s ease-in-out;
  }
  
  .fadeIn {
    opacity: 1;
  }
  
  .fadeOut {
    opacity: 0; 
  }
  
  .ctaButtons {
    display: flex;
    justify-content: center;
    gap: 1rem;
    flex-wrap: wrap;
  }
  
  .ctaButton {
    padding: 0.8rem 1.5rem;
    border-radius: 9999px;
    font-size: 1rem;
    font-weight: bold;
    cursor: pointer;
    transition: all 0.3s ease;
  }
  
  .ctaButton:hover {
    transform: translateY(-3px);
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  }
  
  .rankings {
    composes: ctaButton;
    background-color: #2563eb;
    color: white;
  }
  
  .rankings:hover {
    background-color: #1d4ed8;
  }
  
  .worldsPredict {
    composes: ctaButton;
    background-color: #ea580c;
    color: white;
  }
  
  .worldsPredict:hover {
    background-color: #c2410c;
  }
  
  .contentShowcase {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 3rem;
    align-items: center;
  }
  
  .textContent {
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }
  
  .contentTitle {
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 1rem;
  }
  
  .cyan {
    color: #22d3ee;
  }
  
  .yellow {
    color: #eab308;
  }
  
  .contentText {
    color: #d1d5db;
    line-height: 1.6;
  }
  
  .proViews {
    composes: ctaButton;
    background: linear-gradient(to right, #2563eb, #06b6d4);
    color: white;
    align-self: flex-start;
  }
  
  .proViews:hover {
    background: linear-gradient(to right, #1d4ed8, #0891b2);
  }
  
  .championImage {
    position: relative;
  }
  
  .championImage img {
    max-width: 100%;
    height: auto;
    border-radius: 0.5rem;
  }
  
  .imageOverlay {

  }
  
  @media (max-width: 768px) {
    .mainTitle {
      font-size: 3rem;
    }
  
    .secondaryTitle {
      font-size: 2.5rem;
    }
  
    .contentShowcase {
      grid-template-columns: 1fr;
    }
  
    .championImage {
      order: -1;
    }
  
    .ctaButtons {
      flex-direction: column;
    }
  
    .ctaButton {
      width: 100%;
    }
  }