body {
  margin: 0;
  background-color: #121212; /* Fondo oscuro, como en Vercel */
}

.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.login-box {
  margin: 10em auto;
  max-width: 400px;

  padding: 20px;
  border-radius: 20px; /* Borde más redondeado */
  background: #1a1a1a; /* Fondo oscuro sólido */
  text-align: center;
  border: 1px solid #333; /* Borde sutil alrededor de la caja */
}

@media (max-width: 1400px) {
  .login-box {
    max-width: 400px;
    margin: 10em auto;
  }
}

@media (max-width: 1200px) {
  .login-box {
    max-width: 400px;
    margin: 10em auto;
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  .login-box {
    max-width: 400px;
    margin: 10em auto;
  }

}

@media (max-width: 767px) {
  .login-box {
    margin: 10em auto;
  }

}

.login-header {
  text-align: center;
  margin-bottom: 20px;
}

.login-logo {
  max-width: 80px;
  height: auto;
  margin-bottom: 20px;
}

.loginButton {
  width: 100%;
  padding: 12px 20px;
  margin-bottom: 15px;
  border: none;
  border-radius: 12px; /* Bordes ligeramente redondeados */
  background: linear-gradient(
    135deg,
    #111111,
    #0c0c0c
  ); /* Estilo de gradiente similar al botón de la imagen */
  color: #fff;
  cursor: pointer;
  font-size: large;
  font-weight: bold;
  transition: background 0.3s, transform 0.2s;
  box-shadow: 0 4px 15px #3b83f641; /* Sombra suave para el botón */
}

.activated-button {
  margin-top: 1em;
}

.loginButton:hover {
  transform: scale(1.05);
}

.loginButtonColor {
  --primary-color: #3b82f6;
  --secondary-color: #4f46e5;
  background-image: linear-gradient(
    to right,
    var(--primary-color),
    var(--secondary-color)
  );
}

.loginButtonColor:hover {
  background-image: linear-gradient(to right, #2563eb, #4338ca);
}

.input-container {
  display: flex;
  flex-direction: column;
}

.account-label {
  font-size: 14px;
  font-weight: 600;
  margin: 0.5rem auto;
  color: #bbb; /* Color más claro para el texto */
}

.login-header h2 {
  font-size: 24px;
  font-weight: 700;
  color: #fff; /* Color blanco para el texto del header */
}

.forgot-password {
  color: #bbb;
  text-decoration: none;
}

.forgot-password:hover {
  color: #61b7e3;
}

p {
  transition: opacity 0.3s ease;
}

p.error {
  color: red;
  text-align: center;
  margin-top: 10px;
}

p.error.hidden {
  opacity: 0;
}
