.selected {
  background-color: #007bff;
  color: #fff;
}

.team-image-preview {
  width: 15px;
  height: 15px;
  margin-right: 5px;
  float: left;
}

.dropdown-options li {
  display: flex;
  align-items: center;
}

.dropdown-options img {
  margin-right: 8px;
}

.team-code {
  flex-grow: 1;
  text-align: center;
}

.clear-selection {
  background-color: transparent;
  border: none;
  color: #ffffff;
  font-size: 14px;
  cursor: pointer;
  outline: none;
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
}

.selected-role-button {
    filter: brightness(1.6) blur(0.3px);
    transform: scale(1.3);
    transition: transform 0.3s ease;
  }
  
  