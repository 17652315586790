/* accounts.css */

.lpd-accounts-table {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  font-family: interRegular, sans-serif;
  color: #ffffff;
  padding: 0 15px;
}

.lpd-worlds-logo {
  width: 100%;
  max-width: 300px;
  margin: 20px auto;
  display: block;
}

.lpd-extra-categories {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 20px;
  padding: 10px;
  background-color: #1e2328;
  border-radius: 5px;
}

.lpd-categories,
.lpd-account-card {
  display: grid;
  grid-template-columns: 50px 2fr 1fr 1fr 2fr 1.5fr;
    align-items: center;
  padding: 10px;
  margin-bottom: 2px;
  background-color: #1e2328;
  border-radius: 5px;
}

.lpd-categories {
  font-weight: bold;
  text-transform: uppercase;
}

.lpd-category h1,
h2 {
  text-align: center;
  margin-bottom: 0px !important;
}

.lpd-category,
.lpd-account-card-item {
  padding: 5px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.player-lpd-ranking {
  justify-self: start;
}

.lpd-category1,
.lpd-account-card-item-index {
  text-align: center;
}

.lpd-account-card-name-info {
  display: flex;
  align-items: center;
  justify-self: start;
}

.lpd-player-avatar,
.lpd-league-img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 5px;
  object-fit: contain;
}

.lpd-team-image,
.lpd-role-img {
  width: 50px;
  height: 50px;
  margin-left: 5px;
  object-fit: contain;
}
.lpd-account-card-item-elo {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}
.lpd-account-card-item-elo:hover {
  background-color: rgba(255, 255, 255, 0.1);
}
.lpd-elo-img-container {
  width: 40px;
  height: 40px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.lpd-elo-img {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

.lpd-elo-text {
  display: flex;
  flex-direction: column;
  min-width: 0;
  flex-grow: 1;
}

.lpd-elo-acc-item {
  margin: 0;
  font-size: 16px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@media (max-width: 768px) {
  .lpd-elo-img-container {
    width: 30px;
    height: 30px;
  }

  .lpd-elo-acc-item {
    font-size: 16px;
  }
}
.lpd-winrate {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
}

.lpd-bar {
  flex-grow: 1;
  height: 8px;
  background-color: #444;
  border-radius: 4px;
  overflow: hidden;
  margin-right: 5px;
}

.lpd-bar-fill {
  height: 100%;
  background-color: #c8aa6e;
}

.lpd-winrate-text {
  white-space: nowrap;
  font-size: 10px;
}

.lpd-loader-screen,
.lpd-work-in-progress {
  text-align: center;
  padding: 20px;
  font-size: 16px;
}

.lpd-center-content {
  display: flex;
}

.extra-categories {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 1rem;
}

.extra-category {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.extra-category-filter input {
  background-color: rgba(0, 0, 0, 0.2);
  border: 1px solid var(--border-color);
  color: var(--text-color);
  padding: 8px 10px;
  transition: all 0.3s ease;
  outline: none;
  width: 100%;
  border-radius: 4px;
  font-size: 12px;
}

.role-buttons {
  display: flex;
  gap: 0.5rem;
  flex-wrap: wrap;
}

.extra-category-filter input::placeholder {
  color: rgba(255, 255, 255, 0.5);
}

.extra-category-filter input:focus {
  border-color: var(--accent-color);
  box-shadow: 0 0 0 2px rgba(201, 172, 109, 0.2);
}

.extra-category-filter button {
  background: none;
  border: none;
  padding: 0;
  margin: 0;
  cursor: pointer;
  transition: transform 0.2s ease-in-out;
}

.extra-category-filter button:hover {
  transform: scale(1.1);
}

.extra-category-filter img {
  width: 30px;
  height: 30px;
}

.extra-category-filter .dropdown-container {
  position: relative;
  width: 100%;
}

.extra-category-filter .dropdown-options {
  position: absolute;
  top: 100%;
  width: 100%;
  left: 0;
  background-color: #232323;
  border: 1px solid var(--border-color);
  list-style: none;
  margin: 0;
  padding: 0;
  max-height: 200px;
  overflow-y: auto;
  z-index: 10;
  scrollbar-width: none;
  -ms-overflow-style: none;
  scrollbar-color: transparent transparent;
  border-radius: 0 0 4px 4px;
}

.extra-category-filter .dropdown-options li {
  padding: 8px 10px;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.extra-category-filter .dropdown-options li:hover {
  background-color: var(--hover-bg);
}

.extra-category-filter .dropdown-options::-webkit-scrollbar,
.extra-category-filter .dropdown-options::-webkit-scrollbar-thumb {
  width: 0;
  height: 0;
  display: none;
}

@media (min-width: 768px) {
  .lpd-extra-categories {
    flex-direction: row;
    justify-content: space-between;
  }

  .extra-categories {
    flex-direction: row;
    justify-content: space-around;
    flex-wrap: wrap;
  }

  .extra-category-filter input {
    width: 200px;
  }

  .lpd-elo-acc-item {
    font-size: 16px;
  }

  .lpd-winrate-text {
    font-size: 12px;
  }

  .lpd-bar {
    height: 10px;
  }

  .lpd-elo-img {
    width: 40px;
    height: 40px;
  }

  .extra-category-filter img {
    width: 40px;
    height: 40px;
  }
}
@media (max-width: 767px) {
  .lpd-categories,
  .lpd-account-card {
    grid-template-columns: 30px 2fr 1fr 2fr 1fr;
    font-size: 12px;
  }

  .lpd-category,
  .lpd-account-card-item {
    padding: 3px;
  }

  .lpd-player-avatar,
  .lpd-league-img {
    width: 20px;
    height: 20px;
  }

  .lpd-team-image,
  .lpd-role-img {
    width: 20px;
    height: 20px;
  }

  .lpd-role-column {
    display: none;
  }
  .extra-category {
    justify-content: center;
  }
}

@media (max-width: 480px) {
  .lpd-categories,
  .lpd-account-card {
    grid-template-columns: 30px 2fr 1fr 2fr 1fr;
    font-size: 10px;
  }

  .lpd-category:nth-child(5),
  .lpd-category:nth-child(),
  .lpd-account-card-item:nth-child(5),
  .lpd-account-card-item:nth-child() {
    display: none;
  }
  .extra-category {
    justify-content: center;
  }
}

.lpd-scroll-loader {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 0;
}

.lpd-dot-spinner {
  display: flex;
  justify-content: center;
  align-items: center;
}

.lpd-dot-spinner__dot {
  width: 10px;
  height: 10px;
  background-color: #c8aa6e;
  border-radius: 50%;
  margin: 0 5px;
  animation: dot-spinner 1s infinite ease-in-out;
}

.lpd-dot-spinner__dot:nth-child(2) {
  animation-delay: 0.1s;
}

.lpd-dot-spinner__dot:nth-child(3) {
  animation-delay: 0.2s;
}

.lpd-dot-spinner__dot:nth-child(4) {
  animation-delay: 0.3s;
}

.lpd-dot-spinner__dot:nth-child(5) {
  animation-delay: 0.4s;
}

.lpd-dot-spinner__dot:nth-child(6) {
  animation-delay: 0.5s;
}

.lpd-dot-spinner__dot:nth-child(7) {
  animation-delay: 0.6s;
}

.lpd-dot-spinner__dot:nth-child(8) {
  animation-delay: 0.7s;
}

@keyframes dot-spinner {
  0%,
  100% {
    transform: scale(0.3);
    opacity: 0.3;
  }
  50% {
    transform: scale(1);
    opacity: 1;
  }
}
