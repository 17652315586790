.cl-custom-leagues {
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
  color: #ffffff;
  background-color: #121212;
  font-family: interRegular, sans-serif;
}

.cl-title {
  font-size: 2.5rem;
  text-align: center;
  margin-bottom: 0.5rem;
}

.cl-subtitle {
  font-size: 1.2rem;
  text-align: center;
  color: #a0a0a0;
  margin-bottom: 2rem;
}

.cl-leagues-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1.5rem;
  margin-bottom: 2rem;
}

.cl-league-card {
  background-color: #1e1e1e;
  border-radius: 8px;
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.cl-league-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
}

.cl-league-avatar {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  object-fit: contain;
  margin-bottom: 1rem;
}

.cl-league-name {
  font-size: 1.2rem;
  text-align: center;
  margin-bottom: 1rem;
}

.cl-league-socials {
  display: flex;
  gap: 1rem;
  margin-bottom: 1rem;
}

.cl-league-socials a {
  color: #ffffff;
  font-size: 1.5rem;
  opacity: 0.7;
  transition: opacity 0.3s ease;
}

.cl-league-socials a:hover {
  opacity: 1;
}

.cl-more-button {
  background-color: #3a3a3a;
  color: #ffffff;
  border: none;
  border-radius: 4px;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  transition: background-color 0.3s ease;
}

.cl-more-button:hover {
  background-color: #4a4a4a;
}

.cl-pagination {
  display: flex;
  justify-content: center;
  gap: 1rem;
}

.cl-pagination-button {
  background-color: #3a3a3a;
  color: #ffffff;
  border: none;
  border-radius: 4px;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  transition: background-color 0.3s ease;
}

.cl-pagination-button:hover:not(:disabled) {
  background-color: #4a4a4a;
}

.cl-pagination-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.cl-loading {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 200px;
}

.cl-loading-spinner {
  border: 4px solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  border-top: 4px solid #ffffff;
  width: 40px;
  height: 40px;
  animation: cl-spin 1s linear infinite;
}

@keyframes cl-spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

@media (max-width: 1024px) {
  .cl-leagues-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 768px) {
  .cl-custom-leagues {
    padding: 1rem;
  }

  .cl-title {
    font-size: 2rem;
  }

  .cl-subtitle {
    font-size: 1rem;
  }

  .cl-leagues-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 480px) {
  .cl-leagues-grid {
    grid-template-columns: 1fr;
  }
}
.pagination {
  display: flex;
  justify-content: center;
  gap: 1rem;
  margin-top: 2rem;
}

.pagination-button {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem 1rem;
  background-color: #2a2d31;
  color: #ffffff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.pagination-button:hover:not(:disabled) {
  background-color: #3a3d41;
}

.pagination-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.loader-screen {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
}

.dot-spinner {
  --uib-size: 2.8rem;
  --uib-speed: 0.9s;
  --uib-color: #2563eb;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: var(--uib-size);
  width: var(--uib-size);
}

.dot-spinner__dot {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 100%;
  width: 100%;
}

.dot-spinner__dot::before {
  content: '';
  height: 20%;
  width: 20%;
  border-radius: 50%;
  background-color: var(--uib-color);
  transform: scale(0);
  opacity: 0.5;
  animation: pulse0112 calc(var(--uib-speed) * 1.111) ease-in-out infinite;
  box-shadow: 0 0 20px rgba(18, 31, 53, 0.3);
}

.dot-spinner__dot:nth-child(2) {
  transform: rotate(45deg);
}

.dot-spinner__dot:nth-child(2)::before {
  animation-delay: calc(var(--uib-speed) * -0.875);
}

.dot-spinner__dot:nth-child(3) {
  transform: rotate(90deg);
}

.dot-spinner__dot:nth-child(3)::before {
  animation-delay: calc(var(--uib-speed) * -0.75);
}

.dot-spinner__dot:nth-child(4) {
  transform: rotate(135deg);
}

.dot-spinner__dot:nth-child(4)::before {
  animation-delay: calc(var(--uib-speed) * -0.625);
}

.dot-spinner__dot:nth-child(5) {
  transform: rotate(180deg);
}

.dot-spinner__dot:nth-child(5)::before {
  animation-delay: calc(var(--uib-speed) * -0.5);
}

.dot-spinner__dot:nth-child(6) {
  transform: rotate(225deg);
}

.dot-spinner__dot:nth-child(6)::before {
  animation-delay: calc(var(--uib-speed) * -0.375);
}

.dot-spinner__dot:nth-child(7) {
  transform: rotate(270deg);
}

.dot-spinner__dot:nth-child(7)::before {
  animation-delay: calc(var(--uib-speed) * -0.25);
}

.dot-spinner__dot:nth-child(8) {
  transform: rotate(315deg);
}

.dot-spinner__dot:nth-child(8)::before {
  animation-delay: calc(var(--uib-speed) * -0.125);
}
.leagues-header {
  display: flex;
  align-items: center;
  justify-content: space-between; /* Título a la derecha, botón a la izquierda */
}

.join-league-button {
  margin-right: auto; /* Asegura que el botón esté totalmente a la izquierda */
}

h2 {
  margin-left: auto; /* Asegura que el título esté alineado completamente a la derecha */
}
.league-modal-buttons{
  display: flex;
  gap: 1em;
  justify-content: center;
}


@keyframes pulse0112 {
  0%,
  100% {
    transform: scale(0);
    opacity: 0.5;
  }

  50% {
    transform: scale(1);
    opacity: 1;
  }
}

@media (max-width: 600px) {
  .league-component {
    grid-template-columns: 1fr;
    gap: 1rem;
  }

  .component-socials {
    justify-content: flex-start;
  }

  .component-button {
    justify-content: flex-start;
  }
}