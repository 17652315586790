@font-face {
  font-family: 'BeaufortforLOL-Regular';
  src: url('../fonts/BeaufortForLoL-OTF/BeaufortforLOL-Regular.otf') format('opentype');
  /* Agrega más formatos de fuentes aquí si tienes diferentes extensiones */
  /* También, ajusta la ruta del archivo según la ubicación real en tu proyecto */
}

@font-face {
  font-family: 'BeaufortforLOL-Bold';
  src: url('../fonts/BeaufortForLoL-OTF/BeaufortforLOL-Bold.otf') format('opentype');
  /* Agrega más formatos de fuentes aquí si tienes diferentes extensiones */
  /* También, ajusta la ruta del archivo según la ubicación real en tu proyecto */
}
@font-face {
  font-family: 'Spiegel-Bold';
  src: url('../fonts/Spiegel-OTF/Spiegel-Bold.otf') format('opentype');
  /* Agrega más formatos de fuentes aquí si tienes diferentes extensiones */
  /* También, ajusta la ruta del archivo según la ubicación real en tu proyecto */
}
@font-face {
  font-family: 'Spiegel-Regular';
  src: url('../fonts/Spiegel-OTF/Spiegel-Regular.otf') format('opentype');
  /* Agrega más formatos de fuentes aquí si tienes diferentes extensiones */
  /* También, ajusta la ruta del archivo según la ubicación real en tu proyecto */
}

.font-main{
  font-family: 'BeaufortforLOL-Bold', sans-serif;

}

.font-td{
  font-family: 'Spiegel-Regular', sans-serif;

}
.selected {
    position: relative;
  }
  
  .selected::after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    height: 2px; /* Tamaño de la línea */
    width: 100%;
    background-color: #e38030; /* Color de la línea */
    transition: all 0.3s ease; /* Animación de la línea */
  }

.buttons-pagination{
  display: flex;
  gap: 2em;
  justify-content: center;
  margin-top: 2em;
}

.queueButtons{
  display: flex;
  gap: 2em;
  justify-content: center;
  margin-top: 2em;
  margin-bottom: 2em;
  font-family: 'BeaufortforLOL-Bold', sans-serif;

}
.button-queue{
  background-color: rgba(0, 0, 0, 0.562);
  border: solid 1px #e38030;
  color: white;
  font-weight: 700;
  padding: 1em;
  border-radius: 25px;
  font-family: 'Spiegel-Bold', sans-serif;

}


.button-pag{
  background-color: rgba(0, 0, 0, 0.514);
  border: solid 1px white;
  padding: 1em;
  border-radius: 25px;
  font-family: 'Spiegel-Bold', sans-serif;


}

.searchBar{
  width: 80%;
  display: flex;
  justify-content: center;
  background: center;
  padding: 10px;
  margin-bottom: 8px;
  border: 1px solid #ccccccb9;
  border-radius: 3px;
}

.searchBarContainer{
  display: flex;
  justify-content: center;
}