  .wrapper-prompt{
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80vh;
  }
  .login-prompt {

    max-width: 400px;
    padding: 20px;
    border-radius: 20px; /* Borde más redondeado */
    background: #1a1a1a; /* Fondo oscuro sólido */
    text-align: center;
    border: 1px solid #333; /* Borde sutil alrededor de la caja */
  }
  
  .login-prompt h2 {
    font-size: 24px;
    font-weight: 700;
    color: #fff; /* Color blanco para el texto del header */
  }
  
  .loginButtonPrompt {
    width: 100%;
    padding: 12px 20px;
    border: none;
    margin-top: 20px;
    border-radius: 12px; /* Bordes ligeramente redondeados */
    background: linear-gradient(135deg, #111111, #0c0c0c); /* Estilo de gradiente similar al botón de la imagen */
    color: #fff;
    cursor: pointer;
    font-size: large;
    font-weight: bold;
    transition: background 0.3s, transform 0.2s;
    box-shadow: 0 4px 15px #3b83f641; /* Sombra suave para el botón */
    text-decoration: none; /* Para que el enlace no tenga subrayado */
    display: inline-block; /* Para que el enlace tenga el tamaño adecuado del botón */
  }
  
  .loginButtonPrompt:hover {
    transform: scale(1.05);
  }
  
  .loginButtonColor {
    --primary-color: #3b82f6;
    --secondary-color: #4f46e5;
    background-image: linear-gradient(to right, var(--primary-color), var(--secondary-color));
  }
  
  .loginButtonColor:hover {
    background-image: linear-gradient(to right, #2563eb, #4338ca);
  }
  