:root {
  --background-dark: #1a1c20;
  --card-background: #2a2e35;
  --header-background: #24282f;
  --text-color: #ffffff;
  --accent-color: #c7a760;
  --progress-bar-color: #dc9947;
}

@font-face {
  font-family: "interBold";
  src: url("./fonts/Inter-Bold.ttf");
}
@font-face {
  font-family: "interLight";
  src: url("./fonts/Inter-Light-BETA.ttf");
}
@font-face {
  font-family: "interRegular";
  src: url("./fonts/Inter-Regular.ttf");
}
@font-face {
  font-family: "Druk";
  src: url("./fonts/DrukHeavyItalic.otf");
}
h1 {
  font-size: 48px;
  font-family: "interBold";
}
h2 {
  font-size: 22px;
  font-family: "interBold";
}
h3 {
  font-size: 18px;
  font-family: "interRegular";
}

body {
  -webkit-backdrop-filter: blur(6px);
  backdrop-filter: blur(6px);
  background-color: var(--background-dark);
  color: var(--text-color);
}

/* Account Table CSS */
.worlds-logo {
  max-height: 7em;
  margin: 4rem 0 4rem 0;
}
.accounts-table {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  -webkit-backdrop-filter: blur(6px);
  backdrop-filter: blur(6px);
}
.account-section {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 1400px;
  align-items: center;
}
.account-card {
  display: flex;
  gap: 1rem;
  border-radius: 20px;
  width: 100%;
  background-color: var(--card-background);
  padding: 1rem;
}
.account-card:hover {
  transition: ease-in-out 0.3s;
  background-color: #32363d;
}
.account-card-item {
  width: 300px;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}
.account-card-item-role {
  width: 210px;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}
.account-card-item-index {
  display: flex;
  align-items: center;
  margin-right: 1.5rem;
  margin-left: 1.5rem;
  width: 10px;
}
.account-card-item-elo {
  display: flex;
  gap: 1rem;
}
.account-card-name-info {
  display: flex;
  align-items: center;
}
.account-card-name-info-text {
  display: flex;
  align-items: center;
  gap: 1rem;
}
.elo-text {
  display: flex;
  flex-direction: column;
}
.account-card-part {
  display: flex;
}
.player-avatar {
  width: 70px;
  height: 70px;
  margin-right: 10px;
  object-fit: cover;
}
.role-img {
  width: 50px;
  position: relative;
  left: 2.5rem;
}
.elo-img {
  width: 70px;
  height: max-content;
}
.league-img {
  width: 50px;
  height: 50px;
  object-fit: contain;
}
.categories {
  display: flex;
  justify-content: flex-end;
  width: 1400px;
  padding: 0 2rem 0 2rem;
  background-color: var(--header-background);
  padding: 1rem;
  border-radius: 20px;
  place-items: center;
}
.category {
  width: 270px;
  display: flex;
}
.category h2 {
  color: var(--text-color);
}
.category h2:hover {
  color: var(--accent-color);
}
.category1 {
  margin-right: 4rem;
  color: var(--text-color);
    margin-left: 1rem;
}
.team-image {
  width: 50px;
  position: relative;
  left: 1rem;
}
.winrate-header {
  position: relative;
  left: 4.5rem;
}

.category h2 {
  --s: 0.1em; /* the thickness of the line */
  --c: #c7a760; /* the color */
  padding-bottom: var(--s);
  background: linear-gradient(90deg, var(--c) 50%, #000 0)
      calc(100% - var(--_p, 0%)) / 200% 100%,
    linear-gradient(var(--c) 0 0) 0% 100% / var(--_p, 0%) var(--s) no-repeat;
  -webkit-background-clip: text, padding-box;
  background-clip: text, padding-box;
  transition: 0.5s;
  margin-bottom: 0rem;
}
.category h2:hover {
  --_p: 100%;
}
.container-main {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

/* Container for the progress bar */
.progress-bar {
  width: 200px;
  height: 15px;
  background-color: lightgray;
  border: none;
  border-radius: 5px;
  overflow: hidden;
  margin-left: 1rem;
}
.fill {
  height: 100%;
  background-color: var(--progress-bar-color);
    transition: width 0.3s ease;
}
.empty {
  height: 100%;
background-color: transparent;}

/* Loader animation */
.dot-spinner {
  --uib-size: 2.8rem;
  --uib-speed: 0.9s;
  --uib-color: #c7ac6e;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: var(--uib-size);
  width: var(--uib-size);
}
.dot-spinner__dot {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 100%;
  width: 100%;
}
.dot-spinner__dot::before {
  content: "";
  height: 20%;
  width: 20%;
  border-radius: 50%;
  background-color: var(--uib-color);
  transform: scale(0);
  opacity: 0.5;
  animation: pulse0112 calc(var(--uib-speed) * 1.111) ease-in-out infinite;
  box-shadow: 0 0 20px rgba(18, 31, 53, 0.3);
}
.dot-spinner__dot:nth-child(2) {
  transform: rotate(45deg);
}
.dot-spinner__dot:nth-child(2)::before {
  animation-delay: calc(var(--uib-speed) * -0.875);
}
.dot-spinner__dot:nth-child(3) {
  transform: rotate(90deg);
}
.dot-spinner__dot:nth-child(3)::before {
  animation-delay: calc(var(--uib-speed) * -0.75);
}
.dot-spinner__dot:nth-child(4) {
  transform: rotate(135deg);
}
.dot-spinner__dot:nth-child(4)::before {
  animation-delay: calc(var(--uib-speed) * -0.625);
}
.dot-spinner__dot:nth-child(5) {
  transform: rotate(180deg);
}
.dot-spinner__dot:nth-child(5)::before {
  animation-delay: calc(var(--uib-speed) * -0.5);
}
.dot-spinner__dot:nth-child(6) {
  transform: rotate(225deg);
}
.dot-spinner__dot:nth-child(6)::before {
  animation-delay: calc(var(--uib-speed) * -0.375);
}
.dot-spinner__dot:nth-child(7) {
  transform: rotate(270deg);
}
.dot-spinner__dot:nth-child(7)::before {
  animation-delay: calc(var(--uib-speed) * -0.25);
}
.dot-spinner__dot:nth-child(8) {
  transform: rotate(315deg);
}
.dot-spinner__dot:nth-child(8)::before {
  animation-delay: calc(var(--uib-speed) * -0.125);
}
@keyframes pulse0112 {
  0%,
  100% {
    transform: scale(0);
    opacity: 0.5;
  }
  50% {
    transform: scale(1);
    opacity: 1;
  }
}

/* Home Section CSS */
.home-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 2rem;
  gap: 1rem;
}
.map-container {
  width: 1600px;
  background-color: #4b647419;
  border-radius: 20px;
  margin-bottom: 1em;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 0;
  backdrop-filter: blur(6px);
}
.rsm-geography:focus {
  outline: none !important;
}
.rsm-svg {
  margin-top: -5rem;
  margin-bottom: -10rem;
}
.worlds-logo-leagues {
  width: 280px;
  background-color: #2b7aac;
  padding: 1rem;
  border-radius: 0.5rem;
  position: relative;
}
.home-header {
  font-family: "Druk";
  font-size: 120px;
  letter-spacing: 5px;
}
.map-titles {
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* Proviews Section CSS */
.proviews {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

/* About Section CSS */
.about-container {
  display: flex;
  justify-content: center;
  backdrop-filter: blur(6px);
  padding: 4em;
}
.about-title-container {
  display: flex;
  justify-content: flex-start;
  width: 900px;
}
.about-title {
  font-family: "Druk";
  letter-spacing: 5px;
  font-size: 80px;
}
.about-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  gap: 5rem;
}
.about-avatar {
  width: 250px;
  height: 250px;
  object-fit: cover;
}
.team-wrapper {
  display: flex;
  flex-direction: column;
  gap: 4rem;
}
.team-container {
  display: flex;
  gap: 4rem;
}
.team-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
}
.about-team-title {
  align-self: flex-start;
}
/* === removing default button style ===*/
.wrapper-title {
  border: none;
}
.about-text {
  width: 900px;
}
.about-avatar {
  --color: rgb(3, 146, 255); /* the border color */
  --border: 10px; /* the border thickness*/
  --offset: 20px; /* control the offset*/
  --gap: 5px; /* the gap on hover */

  --_c: var(--color) var(--border), #0000 0 calc(100% - var(--border)),
    var(--color) 0;
  --_o: calc(3 * var(--offset));
  padding: calc(var(--gap) + var(--border))
    calc(var(--gap) + var(--border) + var(--offset))
    calc(var(--gap) + var(--border) + var(--offset))
    calc(var(--gap) + var(--border));
  background: linear-gradient(var(--_c)) var(--_o) var(--_o),
    linear-gradient(90deg, var(--_c)) var(--_o) var(--_o);
  background-size: calc(100% - var(--_o)) calc(100% - var(--_o));
  background-repeat: no-repeat;
  filter: grayscale(0.4);
  transition: 0.5s;
  cursor: pointer;
}
.about-avatar:hover {
  background-position: 0px 0px;
  background-size: calc(100% - var(--offset)) calc(100% - var(--offset));
  filter: grayscale(0);
}
/* button styling */
.wrapper-title {
  --border-right: 6px;
  --text-stroke-color: rgb(255, 255, 255);
  --animation-color: #26a6fc;
  --fs-size: 3em;
  letter-spacing: 3px;
  text-decoration: none;
  font-size: var(--fs-size);
  font-family: "interRegular";
  position: relative;
  text-transform: uppercase;
  -webkit-text-stroke: 1px var(--text-stroke-color);
}
/* this is the text, when you hover on button */
.hover-text {
  position: absolute;
  box-sizing: border-box;
  content: attr(data-text);
  color: var(--animation-color);
  width: 0%;
  inset: 0;
  border-right: var(--border-right) solid var(--animation-color);
  overflow: hidden;
  transition: 0.5s;
  -webkit-text-stroke: 1px var(--animation-color);
}
/* hover */
.wrapper-title:hover .hover-text {
  width: 100%;
  filter: drop-shadow(0 0 23px var(--animation-color));
}
.fancy {
  --b: 2px; /* control the border thickness */
  --w: 450px; /* control the width of the line*/
  --g: -20px; /* control the gap */
  --c: rgb(3, 146, 255);

  width: fit-content;
  padding: 0 1em;
  line-height: 1.6em;
  border: 1px solid;
  color: #fff;
  background: conic-gradient(from 45deg at left, var(--c) 25%, #0000 0) 0,
    conic-gradient(from -135deg at right, var(--c) 25%, #0000 0) 100%;
  background-size: 51% 100%;
  background-origin: border-box;
  background-repeat: no-repeat;
  border-image: linear-gradient(
      #0000 calc(50% - var(--b) / 2),
      var(--c) 0 calc(50% + var(--b) / 2),
      #0000 0
    )
    1/0 var(--w) / calc(var(--w) + var(--g));
  margin-inline: auto;
}

.loader-screen {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10em;
}
.loader-screenNavBar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Fondo oscuro semi-transparente */
  display: flex;
  justify-content: center; /* Centrar horizontalmente */
  align-items: center; /* Centrar verticalmente */
  z-index: 9999; /* Asegura que esté encima de otros elementos */
}

@media (max-width: 1400px) {
  .elo-text h3 {
    font-size: 15px !important;
  }
  .account-section {
    width: 1200px;
  }
  .account-card {
    gap: 1rem;
  }
  .account-card-item {
    width: 240px;
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }
  .categories {
    width: 1200px;
  }
}
@media (max-width: 1200px) {
  .account-section {
    width: 1100px;
  }
  .account-card-item {
    width: 200px;
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }
  .categories {
    width: 1100px;
  }
}
/* Tablets */
@media (min-width: 768px) and (max-width: 1023px) {
  .elo-text h2 {
    font-size: 18px;
  }
  .elo-text h3 {
    font-size: 13px;
  }
  .worlds-logo {
    width: 20rem;
    margin: 4rem 0 4rem 0;
  }
  .account-section {
    width: 750px;
  }
  .categories {
    width: 750px;
  }
  .account-card {
    padding: 0rem;

  }
  .account-card-item {
    width: 170px;
  }
  .account-card-item-role {
    width: 80px;
  }
  .account-card-item-index {
    margin-right: 1rem;
  }
  .player-avatar {
    width: 60px;
  }
  .role-img {
    width: 30px;
  }
  .elo-img {
    width: 50px;
  }
  .team-image {
    width: 40px;
  }
  .category {
    width: 165px;
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }
  .category1 {
    margin-right: 0.5rem;
  }
  .account-card-name-info {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }
  .account-card-item-elo {
    display: flex;
    gap: 0.5rem;
    align-items: center;
  }
  .account-card-name-info-text {
    gap: 0.2rem;
  }
  .winrate-header {
    position: relative;
    left: 2rem;
  }
  .progress-bar {
    width: 100px;
  }
  .home-section {
    margin: 6rem 0 6rem 0;
  }
  .map-container {
    width: 700px;
    padding: 0;
  }
  .container-main {
    display: flex;
    flex-direction: column;
    min-height: 82vh;
  }
  .worlds-logo-leagues {
    width: 150px;
    background-color: #2b7aac;
    padding: 1rem;
    border-radius: 0.5rem;
  }
  .home-header {
    font-family: "Druk";
    font-size: 60px;
    letter-spacing: 5px;
  }
  .map-titles {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .rsm-svg {
    margin-top: -2rem;
    margin-bottom: -5rem;
  }
  .about-title {
    letter-spacing: 5px;
    font-size: 40px;
  }
  .about-section {
    width: 90%;
    gap: 3rem;
  }
  .wrapper-title {
    font-size: 1em;
  }
  .about-avatar {
    width: 150px;
    height: 150px;
  }
  .team-container {
    display: flex;
    gap: 2rem;
    flex-wrap: wrap;
  }
  .fancy {
    display: none;
  }
  .about-text {
    width: 650px;
  }
  .about-avatar {
    --border: 5px;
    --offset: 10px;
  }
  .about-title-container {
    width: 650px;
  }
}
/* Mobile */
@media (max-width: 767px) {
  h1 {
    font-size: 28px;
  }
  h2 {
    font-size: 13px;
  }
  .elo-text h3 {
    font-size: 10px !important;
  }
  .worlds-logo {
    width: 15rem;
    margin: 2rem 0 2rem 0;
  }
  .account-section {
    width: 350px;
  }
  .account-card-name-info-text {
    display: flex;
    align-items: center;
    gap: 0.5rem;
}
  .categories {
    width: 350px;
  }
  .account-card {
    flex-direction: row;
    align-items: center;
    padding: 0rem;
    gap: 0rem;
  }
  .account-card-item {
    width: 100px;
  }
  .account-card-item-role {
    width: 50px;
    position: relative;
  }
  .account-card-item1 {
    display: flex;
    align-items: center;
    margin-right: 1rem;
  }
  .account-card-item-elo {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: relative;
    align-items: center;
    text-align: center;
    gap: 0rem;
  }
  .player-avatar {
    width: 40px;
    height: 40px;
    object-fit: cover;
    margin-right: 0;
  }
  .role-img {
    width: 25px;
    left: 0;
  }
  .elo-img {
    width: 30px;
  }
  .league-img {
    width: 30px;
    display: none;
  }
  .account-card-item-index {
    margin-right: 1rem;
  }
  .progress-bar {
    margin-left: 0;
  }
  .category {
    width: 70px;
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }
  .category1 {
    margin-right: 1rem;
  }
  .team-image {
    width: 25px;
    left: 0;
  }
  .winrate-header {
    position: relative;
    left: 0rem;
    display: none;
  }
  .progress-bar {
    width: 100px;
    display: none;
  }
  .winrate {
    display: none;
  }
  .home-section {
    margin: 8rem 0 6rem 0;
  }
  .map-container {
    width: 350px;
    padding: 0;
  }
  .container-main {
    display: flex;
    flex-direction: column;
    min-height: 78vh;
  }
  .worlds-logo-leagues {
    width: 80px;
    background-color: #2b7aac;
    padding: 0.5rem;
    border-radius: 0.5rem;
    position: relative;
    top: 1rem;
  }
  .home-header {
    font-family: "Druk";
    font-size: 30px;
    letter-spacing: 5px;
  }
  .map-titles {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .rsm-svg {
    margin-top: 0rem;
    margin-bottom: -1rem;
  }
  .about-title {
    letter-spacing: 5px;
    font-size: 40px;
  }
  .about-section {
    width: 90%;
    gap: 3rem;
  }
  .wrapper-title {
    font-size: 1em;
  }
  .about-avatar {
    width: 150px;
    height: 150px;
  }
  .team-container {
    display: flex;
    gap: 2rem;
    flex-wrap: wrap;
  }
  .fancy {
    display: none;
  }
  .about-text {
    width: 330px;
  }
  .about-avatar {
    --border: 5px;
    --offset: 10px;
  }
  .about-title-container {
    width: 350px;
  }
}
