.privacy-policy {
    font-family: interRegular, sans-serif;
    color: #ffffff;
    line-height: 1.6;
    padding: 2rem;
    max-width: 800px;
    margin: 0 auto;
  }
  
  .privacy-policy h1 {
    font-size: 2.5rem;
    color: #00a8ff;
    text-align: center;
    margin-bottom: 2rem;
  }
  
  .privacy-policy h2 {
    font-size: 1.8rem;
    color: #ffa500;
    margin-top: 2rem;
  }
  
  .privacy-policy p, 
  .privacy-policy ul {
    margin-bottom: 1rem;
  }
  
  .privacy-policy ul {
    padding-left: 2rem;
  }
  
  .privacy-policy section {
    margin-bottom: 2rem;
  }