.li-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 2rem;
  color: #ffffff;
  background-color: #121212;
  font-family: interRegular, sans-serif;
}

.li-back-button {
  background-color: transparent;
  color: #ffffff;
  border: none;
  cursor: pointer;
  font-size: 1rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin-bottom: 1rem;
}

.li-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
}

.li-title {
  font-size: 2rem;
  margin: 0;
}

.li-header-buttons {
  display: flex;
  gap: 1rem;
}

.li-info-button,
.li-join-button {
  background-color: #3a3a3a;
  color: #ffffff;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-size: 1rem;
}

.li-info-button:hover,
.li-join-button:hover {
  background-color: #4a4a4a;
}
.li-user-card {
  background-color: #1e1e1e;
  border-radius: 8px;
  padding: 1rem;
  margin-bottom: 2rem;
}

.li-user-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.li-user-rank {
  font-size: 1.5rem;
  font-weight: bold;
}

.li-ranking-list {
  background-color: #1e1e1e;
  border-radius: 8px;
  overflow: hidden;
}

.li-ranking-header,
.li-ranking-item {
  display: grid;
  grid-template-columns: 50px 1fr 100px;
  padding: 1rem;
  align-items: center;
}

.li-ranking-header {
  background-color: #2a2a2a;
  font-weight: bold;
}

.li-ranking-item:nth-child(even) {
  background-color: #252525;
}

.li-top-1 {
  color: #ffd700;
}
.li-top-2 {
  color: #c0c0c0;
}
.li-top-3 {
  color: #cd7f32;
}

.li-verified-icon {
  color: #1da1f2;
  margin-right: 0.5rem;
}

.li-twitter-link {
  color: #1da1f2;
  margin-left: 0.5rem;
}

.li-pagination {
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
}

.li-pagination-button {
  background-color: #4a4a4a;
  color: #ffffff;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  cursor: pointer;
}

.li-pagination-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.li-loading {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 200px;
}

.li-spinner {
  border: 4px solid rgba(255, 255, 255, 0.3);
  border-top: 4px solid #ffffff;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: li-spin 1s linear infinite;
}

@keyframes li-spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.li-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
}

.li-modal {
  background-color: #1e1e1e;
  padding: 2rem;
  border-radius: 8px;
  width: 90%;
  max-width: 400px;
  bottom: 10em;
  position: relative;
}

.li-modal-title {
  margin-top: 0;
}

.li-input {
  width: 100%;
  padding: 0.5rem;
  margin-bottom: 1rem;
  background-color: #2a2a2a;
  border: 1px solid #3a3a3a;
  color: #ffffff;
}

.li-modal-buttons {
  display: flex;
  justify-content: space-between;
}

.li-button {
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.li-button-primary {
  background-color: #4a4a4a;
  color: #ffffff;
}

.li-button-secondary {
  background-color: transparent;
  color: #ffffff;
  border: 1px solid #4a4a4a;
}

.li-message {
  margin-top: 1rem;
  text-align: center;
}

@media (max-width: 600px) {
  .li-container {
    padding: 1rem;
    max-width: 300px;
  }
  .li-header-buttons {
    flex-direction: column;
  }

  .li-info-button,
  .li-join-button {
    padding: 0.3rem;
    font-size: 0.8rem;
  }

  .li-title {
    font-size: 1.5rem;
  }

  .li-ranking-header,
  .li-ranking-item {
    grid-template-columns: 40px 1fr 80px;
    font-size: 0.9rem;
  }
}

.li-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.li-modal {
  background-color: #1e1e1e;
  border-radius: 12px;
  padding: 2rem;
  width: 90%;
  max-width: 600px;
  max-height: 90vh;
  overflow-y: auto;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  position: relative;
}

.li-modal-close {
  position: absolute;
  top: 1rem;
  right: 1rem;
  background: none;
  border: none;
  color: #ffffff;
  font-size: 1.5rem;
  cursor: pointer;
  transition: color 0.3s ease;
}

.li-modal-close:hover {
  color: #f44336;
}

.li-modal-title {
  font-size: 2rem;
  margin-bottom: 1.5rem;
  color: #ffffff;
  text-align: center;
  border-bottom: 2px solid #3a3a3a;
  padding-bottom: 0.5rem;
}

.li-info-content {
  color: #ffffff;
  margin-bottom: 1.5rem;
}

.li-info-item {
  display: flex;
  align-items: flex-start;
  margin-bottom: 1.5rem;
}

.li-info-icon {
  font-size: 1.5rem;
  margin-right: 1rem;
  color: #4a90e2;
  min-width: 30px;
}

.li-info-item p {
  margin: 0;
  line-height: 1.5;
}

.li-prize-list,
.li-tournament-list {
  list-style-type: none;
  padding-left: 0;
  margin-top: 0.5rem;
}

.li-prize-list li,
.li-tournament-list li {
  margin-bottom: 0.5rem;
  padding-left: 1rem;
  position: relative;
}

.li-prize-list li::before,
.li-tournament-list li::before {
  content: "•";
  color: #4a90e2;
  position: absolute;
  left: 0;
}

.li-modal-footer {
  display: flex;
  justify-content: center;
  margin-top: 1.5rem;
}

.li-button {
  padding: 0.75rem 1.5rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.3s ease;
}

.li-button-secondary {
  background-color: #4a90e2;
  color: #ffffff;
}

.li-button-secondary:hover {
  background-color: #3a7bc8;
}

@media (max-width: 768px) {
  .li-modal {
    padding: 1.5rem;
  }

  .li-modal-title {
    font-size: 1.5rem;
  }

  .li-info-icon {
    font-size: 1.2rem;
  }
}

.ch {
  margin-left: initial;
}
