@font-face {
  font-family: "interBold";
  src: url("/src/fonts/Inter-Bold.ttf");
}
@font-face {
  font-family: "interLight";
  src: url("/src/fonts/Inter-Light-BETA.ttf");
}
@font-face {
  font-family: "interRegular";
  src: url("/src/fonts/Inter-Regular.ttf");
}
@font-face {
  font-family: "Druk";
  src: url("/src/fonts/DrukHeavyItalic.otf");
}
.ranking-cards-container h1 {
  font-size: 22px;
  font-family: "interBold";
}
.ranking-cards-container h2 {
  font-size: 22px;
  font-family: "interBold";
}
h3 {
  font-size: 15px;
  font-family: "interRegular";
}
.prediction-home h4 {
  font-size: 28px;
  font-family: "Druk";
  letter-spacing: 2px;
  text-align: center;
}
h5 {
  font-size: 24px;
  font-family: interRegular;
}
.prediction-title h6 {
  font-size: 14px;
  font-family: interLight;
}
.login-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease, visibility 0.3s ease;
}

.login-modal.show {
  opacity: 1;
  visibility: visible;
}
.login-modal-content {
  background: #1e2124;
  color: white;
  padding: 2rem;
  border-radius: 10px;
  box-shadow: 0 5px 30px rgba(0, 0, 0, 0.3);
  max-width: 95%;
  max-height: 95vh;
  overflow-y: auto;
  text-align: center;
  position: absolute; /* Change to absolute positioning */
  top: 25%;
}

.results-container {
  background: #2c3035;
  border-radius: 8px;
  padding: 1.5rem;
  margin-bottom: 2rem;
}

.results-header {
  text-align: center;
  margin-bottom: 1rem;
}

.results-header h2 {
  font-size: 1.5rem;
  margin: 0;
}

.results-content {
  display: flex;
  justify-content: space-between;
}

.results-column {
  flex: 1;
  padding: 0 1rem;
  text-align: center;
}

.results-column h3 {
  font-size: 1.2rem;
  margin-bottom: 1rem;
  color: #9e9e9e;
}

.result-item {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.5rem;
}

.result-item span:first-child {
  font-weight: bold;
}

.result-item-center {
  justify-content: center;
}

.green {
  color: #67d66b;
}
.red {
  color: #df564d;
}
.gray {
  color: #9e9e9e;
}
.team-players {
  display: flex;
  gap: 2rem;
  margin-bottom: 2rem;
}

.team-players-container {
  flex: 1;
  background: #2c3035;
  border-radius: 8px;
  padding: 1rem;
}

.team-title {
  font-size: 1.2rem;
  font-weight: bold;
  margin-bottom: 1rem;
  text-align: center;
  color: #3498db; /* Blue for consistency */
}

.players-table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0 4px;
}

.table-header,
.table-row {
  display: grid;
  grid-template-columns: minmax(120px, 2fr) repeat(6, 1fr);
  gap: 0.5rem;
  align-items: center;
}

.table-header {
  font-weight: bold;
  color: #9e9e9e;
  padding: 0.5rem 0;
  border-bottom: 1px solid #3a3f44;
}

.table-row {
  background-color: #23272a;
  transition: background-color 0.3s ease;
}

.table-row:hover {
  background-color: #2c3035;
}

.table-header > div,
.table-row > div {
  padding: 0.5rem;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 0.8rem;
}

.table-header > div:first-child,
.table-row > div:first-child {
  text-align: left;
}

.player-info {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.player-avatar-pic {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  object-fit: cover;
}

.player-details {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.player-name {
  font-weight: bold;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100px;
}

.player-role {
  font-size: 0.8rem;
  color: #9e9e9e;
}

@media (max-width: 1200px) {
  .table-header,
  .table-row {
    grid-template-columns: minmax(120px, 2fr) repeat(3, 1fr);
  }

  .table-header > div:nth-child(n + 5),
  .table-row > div:nth-child(n + 5) {
    display: none;
  }
}

@media (max-width: 600px) {
  .results-content {
    display: flex;
    justify-content: space-between;
    flex-flow: column;
  }
  .table-header,
  .table-row {
    grid-template-columns: minmax(100px, 2fr) repeat(2, 1fr);
  }

  .table-header > div:nth-child(n + 4),
  .table-row > div:nth-child(n + 4) {
    display: none;
  }

}

.blue-title {
  color: #3498db;
}
.red-title {
  color: #e74c3c;
}

.button3 {
  background: #3498db;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;
}

.margin-button {
  margin-top: 1em;
}
.login-modal-content .button3 + .button3 {
  margin-left: 10px;
}

.button3:hover {
  background: #2980b9;
}

.predictions {
  display: flex;
  background-color: #0f1012;
  min-height: 100vh;
}

.sidebar {
  background-color: rgba(0, 0, 0, 0.5);
  min-height: 100vh;
  width: 240px;
  display: flex;
  flex-direction: column;
  transition: width 0.3s ease;
}

.sidebar-collapsed {
  width: 60px;
}

.sidebar-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
}

.sidebar-title img {
  width: 32px;
  height: 32px;
}

.button10 {
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
}

.button-box {
  display: flex;
  align-items: center;
  justify-content: center;
}

.button-elem svg {
  width: 20px;
  height: 20px;
  fill: #ffffff;
  transition: transform 0.3s ease;
}

.turn-direction {
  transform: rotate(180deg);
}

.sidebar-options {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding: 1rem;
}

.sidebar-options p {
  color: #8a8f98;
  font-size: 0.9rem;
}

.option {
  margin-bottom: 0.5rem;
}

.button-option {
  display: flex;
  align-items: center;
  gap: 1rem;
  width: 100%;
  padding: 0.5rem;
  border: none;
  background: none;
  color: #ffffff;
  font-size: 1rem;
  text-align: left;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.button-option:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

.option-icon {
  width: 24px;
  height: 24px;
}

.credits {
  color: #8a8f98;
  font-size: 0.8rem;
  margin-top: auto;
  padding: 1rem;
}

.credits a {
  color: #ffffff;
  text-decoration: none;
}

.hidden {
  display: none;
}

.predictions-content {
  flex-grow: 1;
  padding: 2rem 0rem;
}

@media (max-width: 768px) {
  .sidebar {
    width: 60px;
  }

  .sidebar-collapsed {
    width: 0;
    overflow: hidden;
  }

  .button-option p {
    display: none;
  }

  .credits {
    display: none;
  }
}

/* Home section */
.prediction-home {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
}
.prediction-card {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
}

.prediction-content {
  display: grid;
  grid-template-columns: 1fr auto 1fr; /* Define tres columnas: izquierda, centro y derecha */
  align-items: center; /* Alinea los elementos verticalmente */
  background: linear-gradient(
    110deg,
    rgba(38, 38, 38, 0.572) 0%,
    rgba(36, 36, 36, 0.663) 42%,
    rgba(20, 20, 20, 0.54) 100%
  );
  padding: 1rem 2rem;
  border-radius: 25px;
  width: 100%; /* Ajusta el ancho al 100% del contenedor padre */
  width: 1500px; /* Ajusta el ancho fijo para hacer el contenedor más largo */
  box-sizing: border-box; /* Asegura que el padding y el border estén incluidos en el ancho total */
  gap: 1rem; /* Espacio entre las columnas */
}

.prediction-content .left {
  grid-column: 1; /* Coloca la sección izquierda en la primera columna */
}

.prediction-content .center {
  grid-column: 2; /* Coloca la sección central en la segunda columna */
  display: flex;
  align-items: center; /* Alinea el contenido verticalmente */
  justify-content: center; /* Centra el contenido horizontalmente */
}

.prediction-content .right {
  grid-column: 3; /* Coloca la sección derecha en la tercera columna */
  display: flex;
  align-items: center; /* Alinea el contenido verticalmente */
  justify-content: flex-end; /* Alinea el contenido a la derecha */
}

.prediction-content .team-image {
  width: 50px; /* Ajusta el tamaño de las imágenes */
  height: 50px; /* Ajusta el tamaño de las imágenes */
}

.prediction-content .right .button3 {
  width: 100px; /* Tamaño fijo para el botón */
  min-width: 100px; /* Evita que el botón se reduzca por debajo de este tamaño */
}



@media (max-width: 768px) {
  .prediction-content {
    grid-template-columns: 1fr; /* Columna única en pantallas pequeñas */
    gap: 1rem; /* Espacio entre los elementos en pantallas pequeñas */
    width: 100%; /* Ajusta el ancho al 100% del contenedor en pantallas pequeñas */
    padding: 1rem; /* Ajusta el padding para pantallas pequeñas */
  }

  .prediction-content .left,
  .prediction-content .center,
  .prediction-content .right {
    grid-column: 1; /* Ocupa toda la columna en pantallas pequeñas */
    text-align: center; /* Centra el texto dentro de cada sección */
    place-content: center;
  }

  .prediction-content .center {
    display: flex;
    flex-direction: column; /* Apila los elementos verticalmente */
    align-items: center; /* Alinea los elementos verticalmente */
    gap: 1rem; /* Espacio entre los elementos del centro */
  }

  .prediction-content .right .button3 {
    width: 100%; /* Botón ocupa el 100% del contenedor en pantallas pequeñas */
    max-width: 200px; /* Tamaño máximo del botón en pantallas pequeñas */
  }
}

.team-one {
  display: flex;
  justify-content: flex-start;
  width: 80px;
}
.team-two {
  display: flex;
  justify-content: flex-start;
  width: 80px;
}
.center {
  display: flex;
  align-items: center;
}

/* Match Details */
.match-details {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
}
.team-players {
  display: flex;
}

.team-players-items {
  display: flex;
}

.detail-score .score-value {
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #252525;
}
.detail-player-card {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.detail-player-card-avatar {
  border: solid 2px #252525;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 120px;
  width: 84px;
}
.detail-avatar {
  width: 80px;
  height: 80px;
  object-fit: cover;
}
.detail-player-card-stats {
  border: solid 2px #252525;
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 5px;
}
.detail-values-player {
  height: 120px;
  display: flex;
  align-items: center;
  border: solid 2px #252525;
  justify-content: center;
}
.detail-values {
  border: solid 2px #252525;
  display: flex;
  justify-content: center;
  height: 30px;
  align-items: center;
}

.prediction-form {
  display: flex;
  gap: 2rem;
}
.form-blue {
  display: flex;
  align-items: center;
  gap: 1rem;
}
.form-red {
  display: flex;
  align-items: center;
  gap: 1rem;
}
.team-score-points {
  background-color: #252525;
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
}

.predict-results {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
}

/* My predictions CSS */

/* HorizontalCard.css */

.horizontal-card {
  display: flex;
  justify-content: space-between;
  border: 1px solid #ccc;
  padding: 20px 0;
  margin: 20px 0;
  width: 100%;
  border-radius: 15px;
}

.left-column,
.right-column {
  flex: 1;
}

.value {
  margin: 5px 0;
}

.checkmarks {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.checkmark {
  font-size: 24px;
  margin: 1px;
  text-align: center;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
}
.ranking-section {
  max-width: 800px;
  margin: 0 auto;
  padding: 2rem;
  color: #ffffff;
}

.ranking-cards-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.ranking-cards-container h1,
h2 {
  text-align: center;
  margin-bottom: 1rem;
}

.user-points-card {
  background-color: #2c3035;
  border-radius: 8px;
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.ranking-card {
  background-color: #23272a;
  border-radius: 8px;
  padding: 0.75rem 1rem;
  display: grid;
  grid-template-columns: 0.5fr 2fr 1fr;
  align-items: center;
  transition: transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
}

.ranking-card:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.ranking-card.header {
  background-color: #2c3035;
  font-weight: bold;
  color: #9e9e9e;
}

.rank,
.username,
.total-points {
  padding: 0.5rem;
}

.rank {
  text-align: center;
  font-weight: bold;
}

.username {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.total-points {
  text-align: right;
}

.gold .rank {
  color: #ffd700;
}
.silver .rank {
  color: #c0c0c0;
}
.bronze .rank {
  color: #cd7f32;
}

.verified-icon {
  color: #3498db;
  font-size: 0.9em;
}

.twitter-icon {
  color: #1da1f2;
  font-size: 0.9em;
  margin-left: 0.5rem;
}

.pagination {
  display: flex;
  justify-content: center;
  gap: 1rem;
  margin-top: 2rem;
}

.pagination-button {
  background-color: #3498db;
  color: white;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  transition: background-color 0.2s ease-in-out;
}

.pagination-button:hover:not(:disabled) {
  background-color: #2980b9;
}

.pagination-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.pagination-button svg {
  width: 20px;
  height: 20px;
}

@media (max-width: 600px) {
  .ranking-card {
    grid-template-columns: 0.5fr 1.5fr 1fr;
  }

  .username {
    flex-direction: column;
    align-items: flex-start;
  }

  .pagination-button {
    padding: 0.5rem;
  }

  .pagination-button span {
    display: none;
  }
}

.total-points {
  flex: 1;
  font-weight: bold;
  color: #ffffff; /* White color for points */
}

/* Gold usernames for the first place */
.ranking-card.gold .rank,
.ranking-card.gold .username,
.ranking-card.gold .total-points {
  color: #ffd700;
}

/* Silver usernames for the second place */
.ranking-card.silver .rank,
.ranking-card.silver .username,
.ranking-card.silver .total-points {
  color: #c0c0c0;
}

/* Bronze usernames for the third place */
.ranking-card.bronze .rank,
.ranking-card.bronze .username,
.ranking-card.bronze .total-points {
  color: #cd7f32;
}

select {
  background-color: #252525;
  color: white;
  padding: 0 20px; /* Add some padding to make it look better */
  border: none; /* Remove the default border */
  border-radius: 8px; /* Add a border radius for a rounded look */
  outline: none; /* Remove the outline when focused */
  position: relative;

  text-align: center;
}

.select-tournament {
  padding: 10px 20px;
  width: 40%;
  place-self: center;
}

/* Style the dropdown arrow */
select::after {
  content: "\25BC"; /* Unicode for down arrow */
  position: absolute;
  top: 50%;
  right: 12px;
  transform: translateY(-50%);
  pointer-events: none; /* Disable click on the arrow */
}

/* Style when hovered */
select:hover {
  background-color: #454545; /* You can change the color on hover */
}

#checkbox {
  display: none;
}

.toggle {
  position: relative;
  width: 40px;
  height: 40px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  transition-duration: 0.5s;
}

.bars {
  width: 100%;
  height: 4px;
  background-color: rgb(255, 255, 255);
  border-radius: 4px;
}

#bar2 {
  transition-duration: 0.8s;
}

#bar1,
#bar3 {
  width: 70%;
}

#checkbox:checked + .toggle .bars {
  position: absolute;
  transition-duration: 0.5s;
}

#checkbox:checked + .toggle #bar2 {
  transform: scaleX(0);
  transition-duration: 0.5s;
}

#checkbox:checked + .toggle #bar1 {
  width: 100%;
  transform: rotate(45deg);
  transition-duration: 0.5s;
}

#checkbox:checked + .toggle #bar3 {
  width: 100%;
  transform: rotate(-45deg);
  transition-duration: 0.5s;
}

#checkbox:checked + .toggle {
  transition-duration: 0.5s;
  transform: rotate(180deg);
}

.hidden {
  display: none;
}

.sidebar-collapsed {
  width: 80px; /* Set the desired width when the sidebar is collapsed */
}

.button10 {
  display: block;
  position: relative;
  width: 56px;
  height: 56px;
  margin: 0;
  overflow: hidden;
  outline: none;
  background-color: transparent;
  border: 0;
  left: -10px;
}

.button-box {
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

.button-elem {
  display: block;
  width: 20px;
  height: 20px;
  margin: 17px 18px 0 18px;
  transform: rotate(180deg);
  fill: #f0eeef;
}

.button5 {
  display: flex;
  height: 2.5em;
  width: 80px;
  align-items: center;
  justify-content: center;
  background-color: #eeeeee4b;
  border-radius: 3px;
  letter-spacing: 1px;
  transition: all 0.2s linear;
  cursor: pointer;
  border: none;
  background: #fff;
  color: black;
  align-self: flex-start;
}

.button5 > svg {
  margin-right: 5px;
  margin-left: 5px;
  font-size: 20px;
  transition: all 0.4s ease-in;
}

.button5:hover > svg {
  font-size: 1.2em;
  transform: translateX(-5px);
}

.button5:hover {
  box-shadow: 3px 3px 3px #d1d1d1, 3px 3px 3px #ffffff;
  transform: translateY(-2px);
}
.footer-modal {
  display: flex;
  justify-content: center;
}

.savepredict {
  width: 160px;
  height: 60px;
  background-color: transparent;
  color: white;
  border: solid 1px white;
  position: relative;
  /* right:5px */
}
.disabled {
  /* Add your desired styles here */
  background-color: #ccc; /* Example background color */
  cursor: not-allowed; /* Change cursor style */
  opacity: 0.3; /* Reduce opacity to indicate disabled state */
  /* Add any other styles as needed */
}

.blue-title-mobile {
  flex-direction: column;
  height: 306px;
  background-color: transparent;
  justify-content: flex-start;
}
.blue-title-mobile-team {
  height: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: solid 2px #252525;
  width: 100%;
}
.blue-title-mobile-value {
  border: solid 2px #252525;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 31px;
}

.form-blue {
  display: flex;
  align-items: center;
  gap: 1rem; /* Espacio entre el avatar del equipo y el puntaje */
}
.form-red {
  display: flex;
  align-items: center;
  gap: 1rem; /* Espacio entre el puntaje y el avatar del equipo */
}
.team-name {
  width: 55px;
  text-align: center;
}
.team-info {
  display: flex;
  align-items: center;
  gap: 1rem; /* Espacio entre el avatar del equipo y el nombre */
}
.disabled {
  pointer-events: none;
  background-color: gray; /* Cambia el color de fondo del botón cuando está deshabilitado */
  opacity: 0.5; /* Reduce la opacidad del botón cuando está deshabilitado */
}
.team-score {
  display: flex;
  align-items: center;
  gap: 1rem; /* Espacio entre el puntaje y el contenedor de puntaje */
}

.turn-direction {
  transform: scaleX(-1);
}

/* Estilos para la notificación emergente */
.notification-container {
  position: fixed;
  top: 100px;
  right: 20px;
  z-index: 9999;
}

.push-notification {
  background: linear-gradient(to right, #1d4ed8, #2563eb);
  color: white;
  border-radius: 5px;
  padding: 10px;
  display: none;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
}

.push-notification.visible {
  display: block;
  animation: pulse 1s infinite; /* Agrega la animación "pulse" */
}

.push-notification-content {
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 0.7em;
}

.gift-icon {
  margin-right: 10px;
}

.close-icon {
  position: absolute;
  top: 5px;
  right: 5px; /* Aumenta la separación hacia la derecha */
  cursor: pointer;
}

@media (max-width: 1400px) {
  .prediction-content {
    width: 800px;
    gap: 10rem;
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  .ranking-section {
    text-align: center;
    padding: 5px;
  }

  .ranking-cards-container {
    padding: 0em;
  }

  .ranking-cards-container h2 {
    font-size: 14px;
  }
  .prediction-content {
    gap: 5rem;
    padding: 1rem 1rem;
  }
  .sidebar {
    width: 180px;
    height: 900px;
  }
  .sidebar-collapsed {
    width: 80px;
  }
  .team-players {
    flex-direction: column;
  }
  .form-blue {
    gap: 0.5rem;
  }
  .form-red {
    gap: 0.5rem;
  }
  .team-avatar {
    width: 40px;
  }
  .team-score-points {
    width: 40px;
    height: 40px;
  }
  .prediction-form {
    gap: 0.5rem;
  }
  .prediction-content {
    width: 500px;
    gap: 3rem;
  }
  .blue-title {
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
  }
  .red-title {
    border-top-right-radius: 0px;
  }
  select {
    top: 0;
    height: 40px;
  }
  .sidebar-title img {
    width: 32px;
    height: 32px;
  }
  .button10 {
    width: 50px;
    height: 50px;
  }
  .button-elem {
    width: 20px;
    height: 20px;
    margin: 13px 15px 0 14px;
  }
  .player-avatar-pic {
    width: 30px;
    height: 30px;
  }
  .blue-title-mobile {
    height: 162px;
  }
  .blue-title-mobile-team {
    height: 70px;
  }
  .blue-title-mobile-value {
    height: 31px;
  }
  .detail-player-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 55px;
  }
  .detail-player-card-avatar {
    align-items: center;
    justify-content: center;
    width: 55px;
    height: 70px;
  }
  .select-tournament {
    width: 80%;
  }
}

@media (max-width: 767px) {
  .select-tournament {
    width: 80%;
  }
  h1 {
    font-size: 10px;
  }
  .ranking-cards-container h2 {
    font-size: 14px;
  }
  h4 {
    font-size: 16px;
  }
  h6 {
    font-size: 12px;
  }

  .button-option {
    flex-direction: column;
  }
  .button-option p {
    font-size: 10px;
  }
  .sidebar-options {
    align-items: center;
  }
  .sidebar-options p {
    font-size: 10px;
  }
  .toggle {
    width: 25px;
    height: 25px;
  }
  .option {
    justify-content: center;
  }

  .team-one {
    display: flex;
    justify-content: center;
    width: 40px;
    margin-top: 1rem;
  }
  .team-two {
    display: flex;
    justify-content: center;
    width: 40px;
    margin-bottom: 1rem;
  }
  .button3 {
    padding: 8px;
    border-radius: 8px;
    font-size: 10px;
  }
  .team-players {
    flex-direction: column;
  }
  .prediction-form {
    flex-direction: column;
  }
  .form-blue {
    flex-direction: column;
  }
  .form-red {
    flex-direction: column;
  }
  select {
    top: 0;
    height: 40px;
  }
  .button5 {
    align-self: center;
  }
  .team-avatar {
    width: 40px;
    height: 40px;
  }
  .team-score-points {
    width: 40px;
    height: 40px;
  }
  .player-avatar-pic {
    width: 30px;
    height: 30px;
  }
  .prediction-content {
    width: 300px;
    gap: 0rem;
  }
  .blue-title {
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
  }
  .red-title {
    border-top-right-radius: 0px;
  }
  .blue-title-mobile {
    height: 155px;
  }
  .blue-title-mobile-team {
    height: 70px;
  }
  .blue-title-mobile-value {
    height: 28.5px;
  }
  .detail-player-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 55px;
  }
  .detail-player-card-avatar {
    align-items: center;
    justify-content: center;
    width: 55px;
    height: 70px;
  }

  .button10 {
    width: 40px;
    height: 40px;
    left: 0px;
  }
  .button-elem {
    width: 15px;
    height: 15px;
    margin: 12px 15px 20px 6px;
  }
  .card-more-info svg {
    position: relative;
    bottom: 30px;
    right: 4px;
  }
  .ranking-section {
    text-align: center;
    padding: 5px;
  }

  .ranking-cards-container {
    padding: 0.5em;
  }
  .username {
    font-size: 0.8em;
  }
}
