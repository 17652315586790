body {
  margin: 0;

}

.register-container {
  margin: 1em auto 1em;
  display: flex;
  place-content: center;

}

.register-box {
  margin: 1em;
  padding: 50px;
  border-radius: 20px;
  background: #1a1a1a;
  text-align: center;
  border: 1px solid #333;
}

.register-header {
  text-align: center;
  margin-bottom: 20px;
}

.register-logo {
  max-width: 80px;
  height: auto;
  margin-bottom: 20px;
}

.registerButton {
  width: 100%;
  padding: 12px 20px;
  margin-bottom: 15px;
  border: none;
  border-radius: 12px;
  background: linear-gradient(135deg, #111111, #0c0c0c);
  color: #fff;
  cursor: pointer;
  font-size: large;
  font-weight: bold;
  transition: background 0.3s, transform 0.2s;
  box-shadow: 0 4px 15px #3b83f641;
}

.registerButton:hover {
  transform: scale(1.05);
}

.registerButtonColor {
  --primary-color: #3b82f6;
  --secondary-color: #4f46e5;
  background-image: linear-gradient(to right, var(--primary-color), var(--secondary-color));
}

.registerButtonColor:hover {
  background-image: linear-gradient(to right, #2563eb, #4338ca);
}

.input-container {
  display: flex;
  flex-direction: column;
}

.account-label {
  font-size: 14px;
  font-weight: 600;
  margin: 0.5rem auto;
  color: #bbb; /* Texto claro */
}

.register-header h2 {
  font-size: 24px;
  font-weight: 700;
  color: #fff; /* Texto blanco */
}

.error-message {
  color: red;
  margin-bottom: 1em;
}

p {
  transition: opacity 0.3s ease;
}

p.error {
  color: red;
  text-align: center;
  margin-top: 10px;
}

p.error.hidden {
  opacity: 0;
}


/* Contenedor del select */
.custom-select-container {
  position: relative;
  width: 100%;
  margin-bottom: 15px;
}

/* Estilo del select */
.custom-select {
  width: 100%;
  padding: 12px 20px;
  border: 1px solid #333;
  border-radius: 12px;
  background: #111111;
  color: #fff;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  appearance: none; /* Elimina el estilo predeterminado del navegador */
  text-align: left;
  display: flex;
  align-items: center;
  position: relative;
}

/* Flecha personalizada */
.custom-select::after {
  content: '\25BC'; /* Unicode para una flecha hacia abajo */
  position: absolute;
  right: 10px;
  font-size: 16px;
  color: #fff;
  pointer-events: none;
}

/* Estilo del select al hacer hover */
.custom-select:hover {
  background: #1a1a1a;
}

/* Estilo del select cuando está en foco */
.custom-select:focus {
  outline: none;
  border-color: #3b82f6; /* Color del borde al estar en foco */
}

/* Opciones del select - No se pueden estilizar directamente en los navegadores modernos */
.custom-select option {
  background: #111111;
  color: #fff;
}

/* Ajuste del ancho del desplegable */
.custom-select-container {
  display: inline-block; /* Asegura que el contenedor se ajuste al ancho del select */
}

.custom-select-container .custom-select {
  box-sizing: border-box;
}

/* Estilo del dropdown - Asegúrate de que se ajuste al ancho del select */
.custom-select-container .custom-select-dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%; /* Asegura que el desplegable tenga el mismo ancho que el select */
  background: #1a1a1a;
  border: 1px solid #333;
  border-top: none;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  max-height: 200px;
  overflow-y: auto;
  z-index: 1000;
  box-sizing: border-box;
}

.update-profile{
  margin-top: 1em;
  margin-bottom: 1em;
}

.profile-info{
  width: 25em;
}

@media (max-width: 767px) {
  .profile-info{
    width: 100%;
  }

  .text-label {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.update-container{
  margin: 5em auto 5em;
  display: flex;
  place-content: center;
}
.profile-details {
  display: flex;
  justify-content: space-around;
  margin-bottom: 20px;
}

.profile-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0;
}

.profile-item p {
  margin: 5px 0;
}

.profile-value {
  display: flex;
  align-items: center;
  justify-content: center;
}

.country-flag {
  width: 24px;
  height: auto;
  margin-right: 8px;
  vertical-align: middle;
  border-radius: 4px;
}

.favorite-name {
  margin-top: 10px;
  font-weight: bold;
}

.image-container {
  width: 100px;
  height: 100px;
  margin: 10px auto;
  overflow: hidden;
  border-radius: 50%;
  box-shadow: 0 4px 6px rgba(59, 130, 246, 0.5);
}

.player-avatar-pic-profile,
.team-avatar-pic-profile {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.team-avatar-pic {
  object-fit: contain;
  background-color: white;
  padding: 10px;
  border-radius: 50%;
}

.favorites-container {
  display: flex;
  justify-content: space-around;
  margin-top: 20px;
}

.favorite-item {
  flex: 1;
  margin: 0 10px;
  text-align: center;
}

.text-discord {
  margin-top: 15px;
  color: #bbb;
}
.username-profile {
  font-size: 2em;
  font-weight: bold;
  margin-bottom: 0.5em;
  text-align: center;
  place-content: center;
  place-items: center;
}

.text-discord-link{
  margin-bottom: 30px;
}