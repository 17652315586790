.info-container {
  background-color: #1e2124;
  color: white;
  padding: 2rem;
  border-radius: 0.5rem;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
  max-width: 32rem;
  margin: 5em auto;
}

.info-title {
  font-size: 1.875rem;
  font-weight: bold;
  text-align: center;
  margin-bottom: 1.5rem;
}

.info-content {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.info-subtitle {
  text-align: center;
  font-size: 1.125rem;
}

.points-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #2c3035;
  padding: 1rem;
  border-radius: 0.375rem;
}

.points-value {
  color: #4ade80;
  font-weight: bold;
  font-size: 1.25rem;
}

.guess-score {
  text-align: center;
  font-weight: 600;
}

.compete-text {
  text-align: center;
  color: #d1d5db;
}

.language-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: #3498db;
  color: white;
  font-weight: bold;
  padding: 0.5rem 1rem;
  border-radius: 0.375rem;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
  margin-top: 2rem;
}

.language-button:hover {
  background-color: #2980b9;
}

.globe-icon {
  margin-right: 0.5rem;
}

@media (max-width: 640px) {
  .info-container {
    padding: 1.5rem;
  }

  .info-title {
    font-size: 1.5rem;
  }

  .info-subtitle {
    font-size: 1rem;
  }

  .points-value {
    font-size: 1.125rem;
  }
}

