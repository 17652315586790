.match-details {
    background-color: #1e1e1e;
    color: #ffffff;
    padding: 1rem;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  .match-header {
    display: flex;
    align-items: center;
    justify-content: center; /* Centra los elementos dentro del contenedor */
    margin-bottom: 1rem;
    width: 100%;
    position: relative;
  }
  
  .back-button {
    background-color: transparent;
    color: #ffffff;
    border: none;
    padding: 0.5rem;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    position: absolute; /* Coloca el botón en la esquina izquierda */
    left: 0;
  }
  
  .back-button:hover {
    background-color: rgba(255, 255, 255, 0.1);
  }
  
  .back-button svg {
    width: 24px;
    height: 24px;
  }
  
  .match-header h2 {
    font-size: 1.5rem;
    margin: 0;
    text-align: center;
    flex-grow: 1;
  }
  .predict-format {
    background-color: #2c2c2c;
    border-radius: 8px;
    padding: 1rem;
    margin-bottom: 1rem;
  }
  
  .prediction-form {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .team-info {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }
  
  .team-avatar {
    width: 40px;
    height: 40px;
    border-radius: 50%;
  }
  
  .team-name h2 {
    font-size: 1.2rem;
    margin: 0;
  }
  
  .team-score-points h3 {
    font-size: 2rem;
    margin: 0;
  }
  
  .form-result select {
    background-color: #3a3a3a;
    color: #ffffff;
    border: none;
    padding: 0.5rem;
    border-radius: 4px;
    font-size: 0.9rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
    width: 100%;
    max-width: 200px;
  }
  
  .form-result select:hover {
    background-color: #4a4a4a;
  }
  
  .predict-results {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
    background-color: #2c2c2c;
    padding: 0.75rem;
    border-radius: 8px;
  }
  
  .winner-display {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }
  
  .winner-label {
    font-weight: bold;
  }
  
  .winner-team {
    background-color: #4a90e2;
    color: #ffffff;
    padding: 0.25rem 0.5rem;
    border-radius: 4px;
    font-weight: bold;
  }
  
  .savepredict {
    background-color: #4a90e2;
    color: #ffffff;
    border: none;
    padding: 0.5rem 1rem;
    border-radius: 4px;
    font-size: 0.9rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .savepredict:hover {
    background-color: #3a7bc8;
  }
  
  .savepredict.disabled {
    background-color: #6c757d;
    cursor: not-allowed;
  }

  
  .team-players-container {
    background-color: #2c2c2c;
    border-radius: 8px;
    overflow: hidden;
    margin-bottom: 1rem;
  }
  
  .team-title {
    padding: 0.75rem;
    font-size: 1rem;
    font-weight: bold;
    text-align: center;
  }
  
  .blue-title {
    background-color: #1a237e;
  }
  
  .red-title {
    background-color: #b71c1c;
  }
  
  .players-table {
    padding: 0.75rem;
  }
  
  .table-header,
  .table-row {
    display: grid;
    grid-template-columns: 2fr repeat(6, 1fr);
    align-items: center;
    padding: 0.4rem;
    border-bottom: 1px solid #3a3a3a;
  }
  
  .table-header {
    font-weight: bold;
    background-color: #3a3a3a;
    font-size: 0.8rem;
  }
  
  .table-row:last-child {
    border-bottom: none;
  }
  
  .player-info {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }
  
  .player-avatar-pic {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    object-fit: cover;
  }
  
  .player-name {
    font-weight: bold;
    font-size: 0.9rem;
  }
  
  .player-role {
    font-size: 0.7rem;
    color: #a0a0a0;
  }
  
  .table-row > div {
    font-size: 0.9rem;
  }
  
  @media (max-width: 768px) {
    .prediction-form {
      flex-direction: column;
      gap: 0.75rem;
    }
  
    .form-result select {
      max-width: 100%;
    }
  
    .predict-results {
      flex-direction: column;
      gap: 0.75rem;
    }
    .team-players{
        display: none;
    }
    .table-header,
    .table-row {
        display: none;
      grid-template-columns: 2fr repeat(3, 1fr);
    }
  
    .table-header > div:nth-child(n+5),
    .table-row > div:nth-child(n+5) {
      display: none;
    }
  }