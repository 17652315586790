@font-face {
  font-family: 'BeaufortforLOL-Regular';
  src: url('../fonts/BeaufortForLoL-OTF/BeaufortforLOL-Regular.otf') format('opentype');
  /* Agrega más formatos de fuentes aquí si tienes diferentes extensiones */
  /* También, ajusta la ruta del archivo según la ubicación real en tu proyecto */
}

@font-face {
  font-family: 'BeaufortforLOL-Bold';
  src: url('../fonts/BeaufortForLoL-OTF/BeaufortforLOL-Bold.otf') format('opentype');
  /* Agrega más formatos de fuentes aquí si tienes diferentes extensiones */
  /* También, ajusta la ruta del archivo según la ubicación real en tu proyecto */
}
@font-face {
  font-family: 'Spiegel-Bold';
  src: url('../fonts/Spiegel-OTF/Spiegel-Bold.otf') format('opentype');
  /* Agrega más formatos de fuentes aquí si tienes diferentes extensiones */
  /* También, ajusta la ruta del archivo según la ubicación real en tu proyecto */
}
@font-face {
  font-family: 'Spiegel-Regular';
  src: url('../fonts/Spiegel-OTF/Spiegel-Regular.otf') format('opentype');
  /* Agrega más formatos de fuentes aquí si tienes diferentes extensiones */
  /* También, ajusta la ruta del archivo según la ubicación real en tu proyecto */
}

.font-main{
  font-family: 'BeaufortforLOL-Bold', sans-serif;

}

.font-td{
  font-family: 'Spiegel-Regular', sans-serif;

}
.selected {
    position: relative;
  }
  
  .selected::after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    height: 2px; /* Tamaño de la línea */
    width: 100%;
    background-color: #e38030; /* Color de la línea */
    transition: all 0.3s ease; /* Animación de la línea */
  }

.buttons-pagination{
  display: flex;
  gap: 2em;
  justify-content: center;
  margin-top: 2em;
}

.queueButtons{
  display: flex;
  gap: 2em;
  justify-content: center;
  margin-top: 2em;
  margin-bottom: 2em;
  font-family: 'BeaufortforLOL-Bold', sans-serif;

}
.button-queue{
  background-color: rgba(0, 0, 0, 0.562);
  border: solid 1px #e38030;
  color: white;
  font-weight: 700;
  padding: 1em;
  border-radius: 25px;
  font-family: 'Spiegel-Bold', sans-serif;

}


.button-pag{
  display: flex;

  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem 1rem;
  background-color: #2a2d31;
  color: #ffffff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.searchBarProviews{
  width: 20%;
  display: flex;
  justify-content: center;
  background: center;
  padding: 10px;
  border: 1px solid #ccccccb9;
  border-radius: 3px;
  color: white;
}

.searchBarContainerProviews{
  display: flex;
  justify-content: space-around;
  align-content: center;
  align-items: center;
  margin-bottom: .9375rem;
  width: 80%;
}


/* Agrega este código a tu archivo proviews.css */

.proviews {
  overflow-x: auto; /* Agrega scroll horizontal si el contenido es más ancho */
}

/* Estilo de la tabla para pantallas más pequeñas */
@media only screen and (max-width: 800px) {
  .proviews {
    padding: 10px;
  }

  .searchBarContainerProviews {
    flex-direction: column;
    align-items: center;
  }

  .searchBarProviews {
    margin-bottom: 10px;
    width: 100%;
  }

  .role-buttons {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  .role-buttons button {
    margin: 5px;
  }

  .buttons-pagination {
    display: flex;
    justify-content: space-around;
    margin-top: 20px;
  }

  .button-pag {
    font-size: 14px;
  }

  .font-main {
    font-size: 16px;
  }

  /* Ocultar ciertas columnas en pantallas más pequeñas */
  .proviews th:nth-child(2), /* Oculta la columna de Patch */
  .proviews th:nth-child(4), /* Oculta la columna de Role */
  .proviews th:nth-child(1), /* Oculta la columna de Role */
  .proviews td:nth-child(1), /* Oculta la columna de Patch */
  .proviews th:nth-child(5), /* Oculta la columna de Role */
  .proviews td:nth-child(6), /* Oculta la columna de Patch */
  .proviews th:nth-child(6), /* Oculta la columna de Role */
  .proviews td:nth-child(7), /* Oculta la columna de Patch */
  .proviews th:nth-child(7), /* Oculta la columna de Role */
  .proviews td:nth-child(5), /* Oculta la columna de Patch */
  .proviews td:nth-child(2), /* Oculta la columna de Patch */
  .proviews td:nth-child(4) { /* Oculta la columna de Role */
    display: none;
  }
}

/* Estilo para la tabla en dispositivos con pantallas más grandes */
@media only screen and (min-width: 801px) {
  /* Agrega estilos específicos para pantallas más grandes si es necesario */
}
.proviews-view {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 80vh; /* Esto hace que la altura sea del 100% del viewport */
  text-align: center;
  color: #fff;
}
.subscribe-button {
  font-size: 20px;
  padding: 10px 20px;
  background-color: #e38030;
  color: #fff;
  cursor: pointer;
  border: none;
  border-radius: 5px;
  margin: 1em;
}

.subscribe-button:hover {
  background-color: #ff9d5a;
}

/* Estilo para la línea vertical */
/* Estilo para la línea vertical */
.winner,
.loser {
  position: relative;
}

.winner::before,
.loser::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 2px; /* Ancho muy delgado */
  height: 100%; /* Altura igual a la altura de la fila */
}

/* Estilo para la línea vertical en las filas de ganador */
.winner::before {
  background-color: green; /* Cambia el color de la línea para los ganadores */
}

/* Estilo para la línea vertical en las filas de perdedor */
.loser::before {
  background-color: red; /* Cambia el color de la línea para los perdedores */
}
