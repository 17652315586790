@import url("https://fonts.googleapis.com/css2?family=Inter:wght@200;300;400;500;600;700&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Inter", sans-serif;
}

:root {
  --dark-grey: #1a202c;
  --medium-grey: #4a5568;
  --light-grey: #e2e8f0;
  --ash: #f7fafc;
  --primary-color: #2b72fb;
  --white: #ffffff;
  --black: #000000;
  --discord: #5865f2;
  --red: #e53e3e;
  --border: 1px solid var(--light-grey);
  --shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}

body {
  font-family: inherit;
  background: linear-gradient(to bottom right, #1a202c, #000000);
  color: var(--white);
  letter-spacing: -0.4px;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

ul {
  list-style: none;
}

a {
  text-decoration: none;
  color: inherit;
}

button {
  border: none;
  background-color: transparent;
  cursor: pointer;
  color: inherit;
}

.btn {
  display: inline-block;
  background: linear-gradient(to right, #2563eb, #3b82f6);
  color: var(--white);
  text-align: center;
  padding: 0.6rem 1.4rem;
  font-size: 1rem;
  font-weight: 500;
  border-radius: 9999px;
  transition: all 0.3s ease;
}

.btn:hover {
  background: linear-gradient(to right, #1d4ed8, #2563eb);
  transform: translateY(-2px);
}

.btn2 {
  display: inline-block;
  background: linear-gradient(to right, #e53e3e, #f56565);
  color: var(--white);
  text-align: center;
  padding: 0.6rem 1.4rem;
  font-size: 1rem;
  font-weight: 500;
  border-radius: 9999px;
  transition: all 0.3s ease;
}

.btn2:hover {
  background: linear-gradient(to right, #c53030, #e53e3e);
  transform: translateY(-2px);
}

.icon {
  background-color: var(--light-grey);
  border-radius: 10px;
  width: 20px;
  height: 20px;
}

.logo {
  margin-right: 1.5rem;
  font-size: 1.5rem;
  font-weight: bold;
  background: linear-gradient(to right, #2563eb, #22d3ee);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

#nav-menu {
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.container-nav {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: 2rem;
  height: 90px;
  padding: 1.2rem 3rem;
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(10px);
  z-index: 5;
}

.menu {
  position: relative;
}

.menu-bar li:first-child .dropdown {
  flex-direction: initial;
  min-width: 480px;
}

.menu-bar li:first-child ul:nth-child(1) {
  border-right: var(--border);
}

.menu-bar li:nth-child(n + 2) ul:nth-child(1) {
  border-bottom: var(--border);
}

.menu-bar .dropdown-link-title {
  font-weight: 600;
  color: var(--white);
}

.menu-bar .nav-link {
  font-size: 1rem;
  font-weight: 500;
  letter-spacing: -0.6px;
  padding: 0.3rem;
  min-width: 60px;
  margin: 0 0.6rem;
  transition: color 0.3s ease;
}

.menu-bar .nav-link:hover,
.dropdown-link:hover {
  color: #22d3ee;
}

.nav-start,
.nav-end,
.menu-bar,
.right-container,
.right-container .search {
  display: flex;
  align-items: center;
}

.dropdown {
  display: flex;
  flex-direction: column;
  min-width: 230px;
  background-color: rgba(0, 0, 0, 0.8);
  backdrop-filter: blur(10px);
  border-radius: 10px;
  position: absolute;
  top: 36px;
  z-index: 1;
  visibility: hidden;
  opacity: 0;
  transform: scale(0.97) translateY(-5px);
  transition: 0.1s ease-in-out;
  box-shadow: var(--shadow);
}

.dropdown.active {
  visibility: visible;
  opacity: 1;
  transform: scale(1) translateY(0);
}

.dropdown ul {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding: 1.2rem;
  font-size: 0.95rem;
}

.dropdown-btn {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0.15rem;
}

.dropdown-link {
  display: flex;
  gap: 0.5rem;
  padding: 0.5rem 0;
  border-radius: 7px;
  transition: 0.1s ease-in-out;
  color: var(--white);
}

.dropdown-link p {
  font-size: 0.8rem;
  color: var(--medium-grey);
}

.right-container {
  display: flex;
  align-items: center;
  column-gap: 1rem;
}

.right-container .search {
  position: relative;
}

.right-container img {
  border-radius: 50%;
}

.mobile-only {
  display: none;
}

.iconApps {
  width: 20px;
  height: 20px;
}

.search input {
  background-color: rgba(255, 255, 255, 0.1);
  border: none;
  border-radius: 9999px;
  padding: 0.7rem;
  padding-left: 2.4rem;
  font-size: 16px;
  width: 100%;
  color: var(--white);
  transition: all 0.3s ease;
}

.search input:focus {
  background-color: rgba(255, 255, 255, 0.2);
  outline: none;
}

.search .bx-search {
  position: absolute;
  left: 10px;
  top: 50%;
  font-size: 1.3rem;
  transform: translateY(-50%);
  opacity: 0.6;
  color: var(--white);
}

#hamburger {
  display: none;
  padding: 0.1rem;
  margin-left: 1rem;
  font-size: 1.9rem;
  color: var(--white);
}

@media (max-width: 1100px) {
  #hamburger {
    display: block;
  }

  .container {
    padding: 1.2rem;
  }

  .menu {
    display: none;
    position: absolute;
    top: 87px;
    left: 0;
    min-height: 100vh;
    width: 100vw;
    background: rgba(0, 0, 0, 0.9);
  }

  .menu-bar li:first-child ul:nth-child(1) {
    border-right: none;
    border-bottom: var(--border);
  }

  .dropdown {
    display: none;
    min-width: 100%;
    border: none !important;
    border-radius: 5px;
    position: static;
    top: 0;
    left: 0;
    visibility: visible;
    opacity: 1;
    transform: none;
    box-shadow: none;
  }

  .menu.show,
  .dropdown.active {
    display: block;
    z-index: 99;
  }

  .dropdown ul {
    padding-left: 0.3rem;
  }

  .menu-bar {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    row-gap: 1rem;
    padding: 1rem;
  }

  .menu-bar .nav-link {
    display: flex;
    justify-content: space-between;
    width: 100%;
    font-weight: 600;
    font-size: 1.2rem;
    margin: 0;
  }

  .menu-bar li:first-child .dropdown {
    min-width: 100%;
  }

  .menu-bar > li:not(:last-child) {
    padding-bottom: 0.5rem;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  }

  .mobile-only {
    display: none;
  }
}

@media (max-width: 767px) {
  .right-container {
    display: none;
  }

  .mobile-only {
    display: block;
  }
}

.content-wrapper {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.wrapper {
  flex-grow: 1;
  min-height: 100%;
}

.footer {
  background: linear-gradient(to right, rgba(26, 32, 44, 0.9), rgba(0, 0, 0, 0.9));
  padding: 20px 0;
  font-family: "Inter", sans-serif;
  text-align: center;
}

.footer .row {
  width: 100%;
  padding: 0.6% 0%;
  color: var(--light-grey);
  font-size: 0.8em;
}

.footer .row a {
  text-decoration: none;
  color: var(--light-grey);
  transition: 0.5s;
}

.footer .row a:hover {
  color: #22d3ee;
}

.footer .row ul {
  width: 100%;
  padding: 0;
  list-style: none;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.footer .row ul li {
  display: inline-block;
  margin: 10px 15px;
}

.footer .row a i {
  font-size: 2em;
  margin: 0% 1%;
  transition: transform 0.3s ease;
}

.footer .row a i:hover {
  transform: translateY(-3px);
}

.social-icons {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.social-icons a {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 50%;
  margin: 0 10px;
  transition: all 0.3s ease;
}

.social-icons a:hover {
  background: rgba(255, 255, 255, 0.2);
  transform: translateY(-3px);
}

.social-icons img {
  width: 20px;
  height: 20px;
}

.footer-bottom {
  margin-top: 20px;
  padding-top: 20px;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  font-size: 0.75em;
  color: var(--light-grey);
}

