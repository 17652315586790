.prizes-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 2rem;
    background-color: #1e2124;
    color: #ffffff;
    font-family: interRegular, sans-serif;
  }
  
  .prizes-title {
    font-size: 2.5rem;
    text-align: center;
    margin-bottom: 2rem;
    color: #3498db;
  }
  
  .prizes-section {
    background-color: #2c3035;
    border-radius: 8px;
    padding: 1.5rem;
    margin-bottom: 2rem;
  }
  
  .section-title {
    font-size: 1.8rem;
    color: #3498db;
    margin-bottom: 1rem;
  }
  
  .sponsor-info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1rem;
  }
  
  .sponsor-image {
    width: 100px;
    height: 100px;
    object-fit: cover;
    border-radius: 50%;
  }
  
  .social-icons {
    display: flex;
    gap: 1rem;
  }
  
  .social-link {
    color: #ffffff;
    font-size: 1.5rem;
    transition: color 0.3s ease;
  }
  
  .social-link:hover {
    color: #3498db;
  }
  
  .prize-list {
    list-style-type: none;
    padding: 0;
  }
  
  .prize-list li {
    padding: 0.5rem 0;
    border-bottom: 1px solid #3a3f44;
  }
  
  .prize-list li:last-child {
    border-bottom: none;
  }
  
  .info-text {
    margin-bottom: 1rem;
    line-height: 1.6;
  }
  
  .action-button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 0.8rem;
    margin-bottom: 1rem;
    background-color: #3498db;
    color: #ffffff;
    border: none;
    border-radius: 4px;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .action-button:hover {
    background-color: #2980b9;
  }
  
  .button-icon {
    margin-right: 0.5rem;
  }
  
  .success-message {
    text-align: center;
    color: #2ecc71;
    margin-top: 1rem;
  }
  
  @media (max-width: 768px) {
    .prizes-container {
      padding: 1rem;
    }
  
    .prizes-title {
      font-size: 2rem;
    }
  
    .section-title {
      font-size: 1.5rem;
    }
  
    .sponsor-info {
      flex-direction: column;
      align-items: flex-start;
    }
  
    .sponsor-image {
      margin-bottom: 1rem;
    }
  
    .social-icons {
      margin-bottom: 1rem;
    }
  }
  
  @media (max-width: 480px) {
    .prizes-title {
      font-size: 1.8rem;
    }
  
    .section-title {
      font-size: 1.3rem;
    }
  
    .sponsor-image {
      width: 80px;
      height: 80px;
    }
  
    .social-link {
      font-size: 1.2rem;
    }
  
    .action-button {
      font-size: 0.9rem;
    }
  }