.mvp-vote-container {
  text-align: center;
  padding: 20px;
  color: #fff;
  min-height: 100vh;
}

.mvp-vote-section {
  margin: 1em;
}

.roles-grid {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin: 40px 0;
}

.role-card {
  background-color: #2e2e2ea4;
  backdrop-filter: blur(6px);
  padding: 20px;
  border-radius: 10px;
  cursor: pointer;
  width: 15em;
  text-align: center;
  transition: transform 0.2s;
}

.role-card:hover {
  transform: scale(1.05);
}

.role-image {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  object-fit: contain;
  margin-bottom: 15px;
}
.vote-button {
  --primary-color: #3b82f6;
  --secondary-color: #4f46e5;
  --text-color: #ffffff;
  --disabled-bg-color: #9ca3af;
  --disabled-text-color: #1f2937;

  width: 100%;
  padding: 0.75rem 1.5rem;
  font-size: 1.125rem;
  font-weight: 500;
  color: var(--text-color);
  background-image: linear-gradient(to right, var(--primary-color), var(--secondary-color));
  border: none;
  border-radius: 0.5rem;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.vote-button:hover:not(:disabled) {
  background-image: linear-gradient(to right, #2563eb, #4338ca);
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
}

.vote-button:focus {
  outline: none;
  box-shadow: 0 0 0 3px rgba(59, 130, 246, 0.5);
}

.vote-button:disabled {
  background-image: none;
  background-color: var(--disabled-bg-color);
  color: var(--disabled-text-color);
  cursor: not-allowed;
  box-shadow: none;
}

@media (max-width: 640px) {
  .vote-button {
    font-size: 1rem;
    padding: 0.625rem 1.25rem;
  }
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 1000;
}

.modal-content {
  background-color: #2e2e2e;
  padding: 30px;
  border-radius: 8px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  box-shadow: 0 0 0 1px rgba(255, 255, 255, 0.05);
  transition: border-color 0.3s ease, box-shadow 0.3s ease;  
  max-width: 600px;
  text-align: center;
  z-index: 1001;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transform: scale(0.95);
  transition: transform 0.2s ease-out;
  position: absolute;
  
}



.modal-content:hover {
  border-color: rgba(255, 255, 255, 0.2);
  box-shadow: 0 0 0 1px rgba(255, 255, 255, 0.1);
}
.modal-content.show {
  transform: scale(1);
}

.players-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
  margin: 20px 0;
}

.player-card {
  background-color: #3e3e3e;
  padding: 15px;
  border-radius: 10px;
  cursor: pointer;
  width: 120px;
  text-align: center;
  transition: transform 0.2s;
  border: 1px solid rgba(255, 255, 255, 0.1);
  box-shadow: 0 0 0 1px rgba(255, 255, 255, 0.05);
  transition: border-color 0.3s ease, box-shadow 0.3s ease;  
}

.player-card:hover {
  transform: scale(1.05);
  border-color: rgba(255, 255, 255, 0.2);
  box-shadow: 0 0 0 1px rgba(255, 255, 255, 0.1);
}

.player-photo {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  object-fit: cover;
  margin-bottom: 10px;
}

.close-modal-button {
  background-color: #e38030;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  font-weight: 500;
  border: none;
  border-radius: 0.375rem;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.close-modal-button:hover {
  background-color: #f58b34;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.15);
}

.close-modal-button:focus {
  outline: none;
  box-shadow: 0 0 0 3px rgba(59, 130, 246, 0.5);
}

.close-modal-button:active {
  transform: translateY(1px);
}

.confirmation-modal,
.error-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5); /* Fondo semitransparente */
  z-index: 1000;
}

.confirmation-modal .modal-content,
.error-modal .modal-content {
  padding: 20px;
  background-color: #2e2e2e;
  border-radius: 10px;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.confirmation-modal .share-button,
.confirmation-modal .close-button {
  display: inline-block;
  padding: 10px 20px;
  margin: 10px;
  border-radius: 5px;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  text-decoration: none;
  color: white;
  cursor: pointer;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
}

.confirmation-modal .share-button {
  background-color: #1da1f2; /* Color azul de Twitter */
}

.confirmation-modal .share-button:hover {
  background-color: #0d8dd7;
}

.confirmation-modal .close-button {
  padding: 10px 20px;
  font-size: 18px;
  background-color: #e38030;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.confirmation-modal .close-button:hover {
  padding: 10px 20px;
  font-size: 18px;
  background-color: #f78d36;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.confirmation-modal .result-image {
  max-width: 100%;
  max-height: 300px; /* Ajusta la altura máxima según sea necesario */
  object-fit: contain; /* Escalar la imagen manteniendo sus proporciones */
  margin-bottom: 20px;
  margin-top: 20px;
}

.error-modal .close-button {
  background: #f00;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  font-weight: 500;
  border: none;
  border-radius: 0.375rem;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.error-modal .close-button:hover {
  background-color: rgb(185, 0, 0);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.15);
}

.error-modal .close-button:focus {
  outline: none;
  box-shadow: 0 0 0 3px rgba(59, 130, 246, 0.5);
}

.error-modal .close-button:active {
  transform: translateY(1px);
}

.error-modal .modal-content p {
  margin: 10px 0;
}

.modal-content {
  opacity: 0;
  transform: translateY(-50%);
  transition: opacity 0.3s ease, transform 0.3s ease;
}

.modal-content.show {
  opacity: 1;
  transform: translateY(0);
  transition: opacity 0.3s ease 0.3s, transform 0.3s ease 0.3s;
}

.modal-content.hide {
  opacity: 0;
  transform: translateY(-50%);
  transition: opacity 0.3s ease, transform 0.3s ease;
}

.confirmation-modal.show,
.error-modal.show {
  opacity: 1;
  transition: opacity 0.3s ease;
}

.confirmation-modal.hide,
.error-modal.hide {
  opacity: 0;
  transition: opacity 0.3s ease;
}

.dark-theme {
  background-color: #121212;
  color: #ffffff;
  padding: 20px;
  border-radius: 8px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  box-shadow: 0 0 0 1px rgba(255, 255, 255, 0.05);
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

.dark-theme:hover {
  border-color: rgba(255, 255, 255, 0.2);
  box-shadow: 0 0 0 1px rgba(255, 255, 255, 0.1);
}

.vote-title {
  font-size: 32px;
  font-weight: bold;
  margin-bottom: 20px;
  text-align: center;
}

.roles-grid {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 20px;
  justify-items: center;
}
.roles-grid > :only-child {
  grid-column: 3 / 4; /* Ocupa la columna 3 */
}
.role-card {
  background-color: #1e1e1e;
  padding: 10px;
  border-radius: 8px;
  text-align: center;
  cursor: pointer;
  transition: background-color 0.3s ease, border-color 0.3s ease;
  border: 1px solid rgba(255, 255, 255, 0.1);
  box-shadow: 0 0 0 1px rgba(255, 255, 255, 0.05);
}

.role-card:hover {
  background-color: #2a2a2a;
  border-color: rgba(255, 255, 255, 0.2);
}


.role-card:hover {
  background-color: #333333;
}

.role-image {
  width: 100%;
  height: 100px;
  margin-bottom: 10px;
  border-radius: 50%;
}

.role-name {
  font-size: 16px;
  font-weight: bold;
}



/* Ajustes para pantallas más pequeñas */
@media (max-width: 1370px) {
  .roles-grid {
    grid-template-columns: repeat(3, 1fr);
  }
  /* Asegúrate de que el único ítem se centre */
  .roles-grid > :only-child {
    grid-column: 2 / 3;
  }
}

@media (max-width: 900px) {
  .roles-grid {
    grid-template-columns: repeat(2, 1fr);
  }
  /* Ajusta el centro del único ítem si es necesario */
  .roles-grid > :only-child {
    grid-column: 2 / 3;
  }
}

@media (max-width: 600px) {
  .roles-grid {
    grid-template-columns: repeat(1, 1fr);
  }
  /* Ajusta el centro del único ítem si es necesario */
  .roles-grid > :only-child {
    grid-column: 1 / 2; /* En una pantalla más pequeña, centrar podría no ser necesario */
  }
}

@media (max-width: 400px) {
  .roles-grid {
    grid-template-columns: 1fr;
  }
  /* Ajusta el centro del único ítem si es necesario */
  .roles-grid > :only-child {
    grid-column: 1 / 2; /* Para pantallas muy pequeñas, ocupa toda la columna */
  }
}

